import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import moment from "moment";

import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  avatar: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56,
  },
  differenceIcon: {
    color: colors.red[900],
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const Budget = ({
  handleRemoveEvent,
  info,
  data,
  filter,
  className,
  lastUpdate,
  ...rest
}) => {
console.log(lastUpdate);

  const classes = useStyles();
  if(info<=0){info=0;}
  if(info>=100){info=100;}

  return (
    <Card>
      <CardContent>
        <div align="center">
          <SemiCircleProgressBar
            align="center"
            percentage={info}
            showPercentValue
          />
        </div>
        <Box height="10px"></Box>
<Box align="center">
{lastUpdate
  ? moment(Date.parse(lastUpdate.toDate())).format(
      "YYYY-MM-DD HH:mm"
    )
  : "Sin fecha"}
</Box>


      </CardContent>

    </Card>
  );
};

export default Budget;
