import React, {useCallback, useState} from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from '@material-ui/core';
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";


const HomeDialogView = ({open, title, onAccept, onCancel, onChange, model, saving}) => {
  var date = new Date();
  var month=date.getMonth()+1;
  if(month>=10){
    var formatedDate = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`

  }else{
    var formatedDate = `${date.getFullYear()}-0${date.getMonth()+1}-${date.getDate()}`

  }
  
  const [errors, setErrors] = useState([]);
  const [openCombo, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const useStyles = makeStyles((theme) => ({
    button: {
      display: "block",
      marginTop: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }));
  const classes = useStyles();

  const handleAccept = () => {


    onAccept();
  };

  return (
    <Dialog maxWidth={180} open={open}>
      <DialogTitle> {title}</DialogTitle>
      <DialogContent>
        <Grid container>
        <Grid item xs={5}>
            <div>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-controlled-open-select-label">
                    Estatus
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="status"
                  open={openCombo}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  value={model.status}
                  onChange={(event) => onChange("status", event.target.value)}
                >
                    <MenuItem value={"TODOS"}>{"Todos"}</MenuItem>
                    <MenuItem value={"NUEVO"}>{"Nuevos"}</MenuItem>
                    <MenuItem value={"ASIGNADO"}>{"Asignados"}</MenuItem>
                    <MenuItem value={"EN RUTA"}>{"En Ruta"}</MenuItem>
                    <MenuItem value={"TERMINADO"}>{"Terminados"}</MenuItem>
                    <MenuItem value={"CANCELADO"}>{"Cancelados"}</MenuItem>
                </Select>
              </FormControl>
            </div>
          </Grid>
        <Grid item xs={5}>
        <TextField
        onChange={(event) => onChange("startdate", event.target.value)}
        id="startdate"
        label="Inicio"
        type="date"
        defaultValue={formatedDate}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
          </Grid> 
          <Grid item xs={5}>
        <TextField
        onChange={(event) => onChange("enddate", event.target.value)}
        id="enddate"
        label="Fin"
        type="date"
        defaultValue={formatedDate}
        className={classes.textField}
        
        InputLabelProps={{
          shrink: true,
        }}
      />
          </Grid> 

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} style={{color: 'white', backgroundColor: '#232e5c'}}>
          Cancelar
        </Button>
        {
          saving
            ?
            <CircularProgress style={{color: '#232e5c'}}/>
            :
            <Button onClick={handleAccept} style={{color: 'white', backgroundColor: '#232e5c'}} autoFocus>
              Aceptar
            </Button>
        }
      </DialogActions>
    </Dialog>
  )
}

export default HomeDialogView
