import React from 'react';
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import {auth, firebase, firestore} from 'firebase';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {
  Avatar, Card, CardContent, CardHeader, Grid, IconButton,
  ListItemAvatar, ListItemSecondaryAction, ListItemText, TextField, Typography,Badge
} from "@material-ui/core";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import {Send as SendIcon,TouchApp as TouchIcon, Clear as ClearIcon} from "@material-ui/icons"
import moment from 'moment-timezone';
import { Divider } from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import { Box, Container, makeStyles, Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon  from '@mui/material/ListItemIcon';
import DriveEta from '@mui/icons-material/DriveEta';
import EmojiPeople from '@mui/icons-material/EmojiPeople';
import Loader from "../../components/Loader";
import app from "../../firebase";
import ChatLayout from "./chat";
import ChatBubble from 'react-chat-bubble';
import { element } from 'prop-types';
import logo from './flame.jpg';
import driver from './driver.jpg';
import person from './person.png';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const ChatView = (folioPedido) => {
const [chatId, setChatId] = useState("");
const [message, setMessage] = useState("");

const [choferImage, setchoferImage] = useState("");
const [usuarioImage, setusuarioImage] = useState("");

const [chatWindow, setchatWindow] = useState("null");
const [chatWindowSalas, setchatWindowSalas] = useState([]);
const [currentMessages, setCurrentMessages] = useState([]);
const [loading, setLoading] = useState(true);
const [folio, setFolio] = useState(folioPedido.folioPedido.folio);
const [pedido, setPedido] = useState(folioPedido.folioPedido);
const [chatpedido,setChatpedido] = useState(false);
const [chatentrega, setChatentrega] = useState(true);

const db=app.firestore()



  useFirestoreConnect([
    {
      collection: "salas",
      orderBy: ["modificado", "desc"],
      where:[["folioPedido","==",folio]],
    },
    
  
  ]);
  useFirestoreConnect([
    {
      collection: "mensajes",
      orderBy: ["fecha", "asc"],
      where:[["idSala","==",chatId]],
    },  ]);
  const messageSelector = useSelector(
    (state) => state.firestore.ordered.mensajes
  );
  const salasSelector = useSelector(
    (state) => state.firestore.ordered.salas
  );
  useEffect(async() => {
    let chats=[];
console.log(pedido.id)
if (salasSelector && salasSelector.length) {
  var chatss = salasSelector.filter(function (el)
  {
    return el.idPedido==pedido.id ;
  }
  );
  if (chatss && chatss.length) {
    let salas=[];
    for (var j = 0; j < chatss.length; j++){
      let element=chatss[j]
      var obj={}
      getNewMessageCount(element.id).then(results=>{
      obj={...element,"new":results};
      salas.push(obj);

      let uniquechatsss = chatss.filter((element, index) => {
        return chatss.indexOf(element) === index;
    });
    
    console.log(uniquechatsss);
    var p_chat = salasSelector.filter(function (el)
    {
      return el.idPedido==pedido.id ;
    });


    var e_chat = salasSelector.filter(function (el)
    {
      return el.idPedido==pedido.id ;
    });



    
      setchatWindowSalas(uniquechatsss);  
      console.log("chatWindowSalas");
      console.log(chatWindowSalas);

      });
    }
    }
}

    if (salasSelector && salasSelector.length) {
    var pedidoArray = salasSelector.filter(function (el)
    {
      return el.tipo =="pedido" && el.idPedido==pedido.id ;
    }
    );
    console.log("pedidoArray");
    console.log(pedidoArray);
  if (pedidoArray.length>0){
    console.log(pedidoArray[0]);
  }else{
    let size1=0
    await db.collection('salas').where("idPedido","==",pedido.id).where("tipo","==","pedido").get().then(snapshot => {
      size1=snapshot.size;
      console.log(size1);
      if (size1==0 && chatpedido==false){
        setChatpedido(true)
        db.collection("salas").doc(pedido.id+"p").set({
          fecha:app.firestore.Timestamp.now(),
          modificado:app.firestore.Timestamp.now(),
          folioPedido:folio,
          chofer:pedido.chofer,
          cliente:pedido.cliente,
          idCliente:pedido.cliente.id,
          idCallcenter:"callcenter",
          idPedido:pedido.id,
          nombreCliente:pedido.nombre,
          pedido:pedido,
          cliente:pedido.cliente,
          terminado:0,
          tipo:"pedido"},{merge: true});
      }
  });
  }
  }
  if (salasSelector && salasSelector.length) {
    var entregaArray = salasSelector.filter(function (el)
    {
      return el.tipo =="entrega" && el.idPedido==pedido.id;
    }
    );
    console.log("entregaArray");
    console.log(entregaArray);
  if(entregaArray.length>0){
    console.log(entregaArray[0]);
  }else{
    let size1=0
    await db.collection('salas').where("idPedido","==",pedido.id).where("tipo","==","entrega").get().then(snapshot => {
      size1=snapshot.size;
      if (size1==0){
        db.collection("salas").doc(pedido.id+"e").set({
          fecha:app.firestore.Timestamp.now(),
          modificado:app.firestore.Timestamp.now(),
          folioPedido:folio,
          chofer:pedido.chofer,
          cliente:pedido.cliente,
          idCliente:pedido.cliente.id,
          idCallcenter:"callcenter",
          idPedido:pedido.id,
          nombreCliente:pedido.cliente.nombre,
          pedido:pedido,
          cliente:pedido.cliente,
          terminado:0,
          tipo:"entrega"},{merge: true});
      }
  });
  }
  }
console.log(chatWindowSalas);

    setLoading(false);
  }, [salasSelector]);
  useEffect(() => {
    if(chatWindow!="null"){
      if (messageSelector) {
        let _messages = [...messageSelector];

        setCurrentMessages(_messages);
  
        let _m=[];
        let _url="";
        if(chatWindow.tipo=="pedido"){
          _url=person;
        }else{
          _url=driver;
        }
        let _type=0;
        _messages.forEach(message=>{
         if(message.idUsuario=="callcenter"){_type=0}else{_type=1};
         if(message.idUsuario=="callcenter"){_url=logo}else{_url=person};

          let data={
            "type" : _type  ,
            "image": _url,
            "text": message.mensaje,
            "date":moment(Date.parse(message.fecha.toDate())).format(
              "YYYY-MM-DD HH:mm"
            )
          };
  
          _m.push(data);
  
        });
        setCurrentMessages(_m);
  
  
  
      }
    }

  }, [messageSelector]);
  useEffect( () => {
    
    if (chatId!="") {
      const results=chatWindowSalas.filter(s => s.id ==chatId);
      setchatWindow(results[0]);


    }
  }, [chatId]);
  const _scrollChatToBottom =  () => {
    if (this.chatWindow !== undefined) {
      const node = ReactDOM.findDOMNode(this.chatWindow["current"]);
      node.scrollTo(0, node.scrollHeight + 100);
    }
  };

  const _scrollChatToBottomSalas =  () => {
    if (this.chatWindowSalas !== undefined) {
      const node = ReactDOM.findDOMNode(this.chatWindowSalas["current"]);
      node.scrollTo(0, node.scrollHeight - 100);
    }
  };
  
  const setMessages =  (sala) => {
    setChatId(sala.id);
    setchatWindow(sala);
    db.collection("mensajes").where("idSala","==",sala.id).get().then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
          doc.ref.update({
              visto: 1
          });
      });
  });
  };

  const updateInputValue=(evt) =>{
    setMessage( evt);};

  const sendMessage=()=>{
     const newMessage={
      fecha:firestore.Timestamp.now(),
      "idUsuario":"callcenter",
      "nombreUsuario":"Egas",
      "visto":0,
      "mensaje":message,
      "idSala":chatId
    }
    if(chatId!=""){
      db.collection("mensajes").add(newMessage);
      db.collection("salas").doc(chatId).update({"modificado":firestore.Timestamp.now()});
    }
    setMessage("");
  }; 
  const getNewMessageCount=async (idSala)=>{
    let size=1;
    await db.collection('mensajes').where("idSala","==",idSala).where("idUsuario","!=","callcenter").where("visto","==",0).get().then(snapshot => {
      size=snapshot.size;
  });
  return(size); 

  }; 
  return  (
    <div className="dashboard animated slideInUpTiny animation-duration-3">
         {loading==false ?  null:<Loader /> }
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container spacing={6}>
          <Grid item xs={12}>
              <Card>
                <CardHeader title="Conversaciones"/>
                <CardContent>
                <div  className="chat-main-content"
                      style={{overflow: 'hidden', height: '550px', overflowY: 'scroll'}}>
                     <List>
                     {loading==false ? chatWindowSalas.map(sala => (
                      <ListItemButton onClick={()=>setMessages(sala)}>
                      <ListItemIcon>
                      <Badge badgeContent={sala.new} color="primary">

                      {sala.tipo=="pedido" ? (< EmojiPeople/>) : (<DriveEta />)}
                      </Badge>

                    </ListItemIcon>
                    <ListItemText primary={sala.tipo=="pedido" ? sala.cliente.nombre:sala.chofer? (sala.chofer.nombre):"Sin Chofer" } secondary=  {sala.modificado
                  ? moment(Date.parse(sala.modificado.toDate())).format(
                      "YYYY-MM-DD HH:mm"
                    )
                  : "Sin fecha"}/>
                      </ListItemButton>
                    )) : null}
                       </List>
                  </div>  
                </CardContent>
              </Card>
            </Grid>
          
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
          <Grid item xs={12}>
              <Card>
                <CardHeader title="Mensajes"/>
                <CardContent>
                  <center>
                    <strong>{chatWindow != "null"  ? (chatWindow.tipo=="pedido" ? (chatWindow.cliente.nombre):chatWindow.chofer? (chatWindow.chofer.nombre):null ) : null}</strong>
                  </center>
                  <div className="chat-main-content"
                       style={{overflow: 'hidden', height: '480px'}}>

                        {chatWindow != "null" ? 
                        (<ChatBubble messages = { currentMessages} />) : null

                        }


                  </div>
                  <div>
                    <Grid container alignItems="center">
                      <Grid item xs={11}>
                        <TextField
                          label="Mensaje"
                          margin="dense"
                          variant="filled"
                          multiline
                          fullWidth
                          rowsMax="4"
                          value={message || ""}
                          defaultValue={message}
                          onChange={(event) => updateInputValue(event.target.value)}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton onClick={sendMessage}><SendIcon/></IconButton>
                      </Grid>
                    </Grid>
                  </div>
                </CardContent>
              </Card>
            </Grid>

          </Grid>
        </Grid>

      </Grid>
      <NotificationContainer/>
    </div>
  );
};

export default ChatView;
