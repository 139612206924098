import React, { useCallback, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Crop from "../../components/Crop";

const HomeDialogView = ({
  open,
  title,
  onAccept,
  onCancel,
  onChange,
  model,
  saving,
}) => {
  const { logo = "" } = model;
  const [errors, setErrors] = useState([]);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({
    width: 900,
    height: 900,
  });
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const handleSubmission = () => {};
  const handleAccept = () => {
    let _errors = [];
    if (!model.nombre) {
      _errors.push("nombre");
    }

    if (!model.descripcion) {
      _errors.push("descripcion");
    }

    if (!model.descripcion) {
      _errors.push("precio");
    }

    if (!model.activo) {
      model.activo = false;
    }

    setErrors(_errors);

    if (_errors.length) {
      console.log(errors);
      return;
    }
    onAccept();
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(
    async (picture) => {
      try {
        return await getCroppedImg(picture, croppedAreaPixels, rotation);
      } catch (e) {
        console.error(e);
        return null;
      }
    },
    [croppedAreaPixels, rotation]
  );

  const handleBeforeSubmit = async () => {
    try {
      let _images = [];
      let image;

      if (model.logo) {
        const img = await showCroppedImage(model.logo);

        if (img.size > 200000) {
          const url = await getUrl(img);

          const resp = await fetch(url);
          const blob = await resp.blob();
          const file = new File([blob], "file", { type: "image/png" });
          const options = {
            maxSizeMB: 0.2,
            maxWidthOrHeight: 600,
            useWebWorker: true,
          };

          try {
            const compressedFile = await imageCompression(file, options);
            image = compressedFile;
          } catch (error) {
            console.log(error);
          }
        } else {
          image = img;
        }

        setCrop({ x: 0, y: 0 });
        setZoom(1);
        setCroppedAreaPixels(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Agregar {title}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={5}>
            <TextField
              error={errors.includes("nombre")}
              variant="outlined"
              value={model.nombre}
              fullWidth
              onChange={(event) => onChange("nombre", event.target.value)}
              helperText={"Nombre"}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              error={errors.includes("descripcion")}
              variant="outlined"
              value={model.descripcion}
              fullWidth
              multiline
              onChange={(event) => onChange("descripcion", event.target.value)}
              helperText={"Descripción"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={errors.includes("version")}
              variant="outlined"
              value={model.version}
              fullWidth
              multiline
              onChange={(event) => onChange("version", event.target.value)}
              helperText={"Version"}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={3} xs={3}>
            <input type="file" name="file" onChange={changeHandler} />
          </Grid>

          <Grid item md={9} xs={9}>
            <Crop
              _state={
                logo
                  ? {
                      image:
                        typeof logo === "string"
                          ? logo
                          : URL.createObjectURL(logo),
                      crop: { x: 0, y: 0 },
                      zoom: 1,
                      aspect: 9 / 3,
                    }
                  : null
              }
              onCroppedImage={(imageCropped) => onChange("logo", imageCropped)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          style={{ color: "white", backgroundColor: "#232e5c" }}
        >
          Cancelar
        </Button>
        {saving ? (
          <CircularProgress style={{ color: "#232e5c" }} />
        ) : (
          <Button
            onClick={handleAccept}
            style={{ color: "white", backgroundColor: "#232e5c" }}
            autoFocus
          >
            Aceptar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default HomeDialogView;
