import React, {useEffect} from "react";
import {Box, Button, Container, makeStyles, Typography} from "@material-ui/core";
import Page from "../../components/Page";
import Dialog from './Dialog'
import Results from "./Results";
import {useFirestoreConnect} from "react-redux-firebase";
import Services from '../../services';
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const title = "Promociones";

const Dashboard = () => {
  useFirestoreConnect([{collection: "promociones"},]);
  const promocionesSelector = useSelector((state) => state.firestore.ordered.promociones);

  const [openCombo, setComboOpen] = React.useState(false);
  const [promociones, setPromociones] = React.useState([]);
  const [model, setModel] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const handleComboClose = () => {
    setComboOpen(false);
  };
  const handleComboOpen = () => {
    setComboOpen(true);
  };

  const classes = useStyles();

  useEffect(() => {
    try {
      if (promocionesSelector) {
        let _promociones = [...promocionesSelector];

        setPromociones(_promociones);
      }
    } catch (e) {
      console.log(e);
    }
  }, [promocionesSelector]);

  const add = async () => {
    try {
      let data = {
        nombre: model.nombre,
        descripcion: model.descripcion,
        inicio: model.inicio !== undefined ? new Date(Date.UTC(model.inicio)) : new Date,
        fin: model.fin !== undefined ? new Date(Date.UTC(model.fin)) : new Date(),
        activo: model.activo,
        url: model.url !== undefined ? model.url : "",
        informacion: model.informacion !== undefined ? model.informacion : "",
      }

      if (model.imagen) {
        const url = await Services.uploadFile('promociones/' + model.imagen.name, model.imagen);
        data.imagen = url;
        data.imagenPath = 'promociones/' + model.imagen.name;
      }


      await Services.addDocument('promociones', data);

    } catch (error) {
      console.log(error);
    } finally {
      setModel([]);
      setOpen(false);
    }
  };

  return (
    <Page className={classes.root} title={title}>
      <Container maxWidth={false}>
        <Box display="flex" p={1} mt={2}>
          <Box flexGrow={1}>
            <Typography variant="h5">{title}</Typography>
          </Box>
          <Box>
            <Button
              color="primary"
              onClick={() => {
                setOpen(true);
                setModel({
                  image: null
                })
              }}
              variant="contained"
            >
              Nuevo
            </Button>
          </Box>
        </Box>
        <Box mt={3}>
          <Results data={promociones} title={title}/>
        </Box>
        <Box height="60"/>
      </Container>

      <Dialog
        open={open}
        onAccept={add}
        onCancel={() => {
          setOpen(false);
          setModel({});
        }}
        onChange={(key, value) => {
          if (key === 'imagen') {
            setModel({...model, [key]: value, imagen: value});
          }
          else if (key === 'activo') {
            setModel({...model, [key]: value, activo: model.activo === true ? false : true});
          }
          else {
            setModel({...model, [key]: value});
          }
        }}
        model={model}
        title={title}
      />
    </Page>
  );
};

export default Dashboard;
