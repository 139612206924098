import React, {useEffect, useState} from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage';
import { Button } from '@material-ui/core';

const Crop = ({_state, onCroppedImage}) => {
  const [state, setState] = useState({
    image: 'https://firebasestorage.googleapis.com/v0/b/edesarrollos-iot.appspot.com/o/logos%2Fwhite-logo.aa71b3ec.png?alt=media&token=2ed93fd3-0025-4f61-ae97-5b2fc0f1aa3e',
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 4 / 3,
  });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  useEffect(() => {
    if(_state) {
      setState(_state);
    }
  }, [_state]);


  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  } 

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        state.image,
        croppedAreaPixels,
        0
      );

      onCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }

  const onCropChange = (crop) => {
    setState({...state, crop: crop});
  }
 
  const onZoomChange = (zoom) => {
    setState({...state, zoom: zoom});
  }
  
  return (
    <div style={{position: 'relative', width: '100%', height: 300}}>
      <Cropper
        image={state.image}
        crop={state.crop}
        zoom={state.zoom}
        aspect={state.aspect}
        onCropChange={onCropChange}
        onCropComplete={onCropComplete}
        onZoomChange={onZoomChange}
      />
      <Button
        onClick={showCroppedImage}
        variant="contained"
        color="primary"
      
      >Terminar edición</Button>
    </div>
  )
}

export default Crop
