import React, { useEffect } from "react";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Container,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Page from "../../components/Page";
import Dialog from "./Dialog";
import Results from "./Results";
import Services from "../../services";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const title = "Precio por Litro";

const Dashboard = () => {
  useFirestoreConnect([
    {
      collection: "precios",
    },
  ]);
  const [openCombo, setComboOpen] = React.useState(false);
  const [gaseras, setGaseras] = React.useState([]);
  const [model, setModel] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const handleComboClose = () => {
    setComboOpen(false);
  };

  const handleComboOpen = () => {
    setComboOpen(true);
  };

  const gaserasSelector = useSelector(
    (state) => state.firestore.ordered.precios
  );

  const classes = useStyles();

  useEffect(() => {
    try {
      if (gaserasSelector) {
        let _gaseras = [...gaserasSelector];

        setGaseras(_gaseras);
      }
    } catch (e) {
      console.log(e);
    }
  }, [gaserasSelector]);

  const add = async () => {
    try {
      let data = {
        precio: model.precio,
      };

      await Services.updateDocument("precios", model.id, data);
    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
    }
  };

  return (
    <Page className={classes.root} title={title}>
      <Container maxWidth={false}>
        <Box display="flex" p={1} mt={2}>
          <Box flexGrow={1}>
            <Typography variant="h5">{title}</Typography>
          </Box>
          <Box></Box>
        </Box>
        <Box mt={3}>
          <Results data={gaseras} title={title} />
        </Box>
        <Box height="60"></Box>
      </Container>
      <Dialog
        open={open}
        onAccept={add}
        onCancel={() => {
          setOpen(false);
          setModel({});
        }}
        onChange={(key, value) => {
          if (key === "image") {
            setModel({
              ...model,
              [key]: value,
              logo: URL.createObjectURL(value),
            });
          } else if (key === "activo") {
            setModel({
              ...model,
              [key]: value,
              activo: model.activo === true ? false : true,
            });
          } else {
            setModel({ ...model, [key]: value });
          }
        }}
        model={model}
        title={title}
      />
    </Page>
  );
};

export default Dashboard;
