import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import Table from "../../components/Table";
import { Card, makeStyles, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import PrintIcon from "@material-ui/icons/LocalPrintshop";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import { SyncAlt } from "@material-ui/icons";
import QRCode from "easyqrcodejs";
const header = [
  {
    prop: "nombre",
    name: "Nombre",
  },
  {
    prop: "apellido",
    name: "Apellido",
  },
  {
    prop: "email",
    name: "Email",
  },
  {
    prop: "rol",
    name: "rol",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({ data, urls, handleDelete }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handlePrint = (id) => {
    const index = data.map((d) => d.id).indexOf(id);
    const a = document.createElement("a");
    a.href = urls[index];
    a.download = "Image.png";
    a.click(); //Downloaded file
  };

  return (
    <Card style={{ padding: 0 }}>
      {data && (
        <Table
          header={header}
          data={data}
          paginated
          filter={["dispositivo", "id_unico", "provedor", "tipo"]}
          extraRows={[
            {
              prop: "delete",
              name: "Quitar Relacion",
              cell: (row) => (
                <IconButton
                  style={{ backgroundColor: "#de091e", color: "white" }}
                  onClick={() => handleDelete(row)}
                >
                  <DeleteIcon />
                </IconButton>
              ),
            },
          ]}
        />
      )}
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;
