import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: '100%',
    height: '80vh'
  },
  headBG: {
      backgroundColor: '#e0e0e0'
  },
  borderRight500: {
      borderRight: '1px solid #e0e0e0'
  },
  messageArea: {
    height: '70vh',
    overflowY: 'auto'
  }
});

const Chat = () => {
  const classes = useStyles();

  return (
      <div>
      
      
                <List className={classes.messageArea}>
                    <ListItem key="1">
                                <ListItemText align="right" primary="Hey man, What's up ?"></ListItemText>
                                <ListItemText align="right" secondary="09:30"></ListItemText>
                    </ListItem>
                    <ListItem key="2">
                                <ListItemText align="left" primary="Hey, Iam Good! What about you ?"></ListItemText>
                                <ListItemText align="left" secondary="09:31"></ListItemText>
                    </ListItem>
                    <ListItem key="3">
                                <ListItemText align="right" primary="Cool. i am good, let's catch up!"></ListItemText>
                                <ListItemText align="right" secondary="10:30"></ListItemText>
                    </ListItem>
                </List>
            
             
      </div>
  );
}

export default Chat;