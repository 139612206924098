import React, { useState } from "react";
import moment from "moment-timezone";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { Container, IconButton, Box, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/add";
import Alert from "../../components/Alert";
import Services from "../../services";
import Loader from "../../components/Loader";
import Table from "../../components/Table";
import DeleteDialog from "../../components/DeleteDialog";
import UsersDialog from "./userDialogView";
import Results from "./Results";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import SyncAlt from "@material-ui/icons/SyncAlt";
import QRCode from "easyqrcodejs";
import app from "../../firebase";
import SyncDialog from "./ModalSync.js";

const header = [
  {
    prop: "displayName",
    name: "Nombre",
  },
  {
    prop: "email",
    name: "Correo electrónico",
  },
];

const UsersView = () => {
  useFirestoreConnect([{ collection: "usuarios", orderBy: ["fecha", "desc"] }]);
  const db = app.firestore();
  const [devices, setDevices] = useState([]);
  const [deviceInfo, setInfo] = React.useState([]);
  const [urls, setUrls] = useState([]);
  const code = React.createRef();
  const users = useSelector((state) => state.firestore.ordered.usuarios);
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("");
  const [error, setError] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [user, setUser] = useState(null);
  const [saving, setSaving] = useState(false);
  const [message, setMessage] = useState("");
  const [deleting, setDeleting] = useState(false);
  const [openSync, setOpenSync] = React.useState(false);

  if (!isLoaded(users)) {
    return <Loader />;
  }
  const deleteUser = async () => {
    user.activo = "no";
    user.borrado = app.firestore.Timestamp.now();
    console.log(user);
    db.collection("usuarios").doc(user.id).update(user);
    setOpenDelete(false);
  };

  const getQR = (data2) => {
    try {
      if (data2.length && data2) {
        data2.forEach((d) => {
          new QRCode(code.current, {
            text: d.dispositivo,
            width: 256,
            height: 256,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
            dotScale: 1,
            onRenderingEnd: function (_, dataURL) {
              var index = urls.findIndex((x) => x == dataURL);
              if (index === -1) {
                urls.push(dataURL);
              } else {
                console.log("object already exists");
              }
              db.collection("dispositivos")
                .doc(d.dispositivo)
                .get()
                .then((querySnapshot) => {
                  const data = querySnapshot.data();
                  var index = deviceInfo.findIndex(
                    (x) => x.id_unico == data.id_unico
                  );
                  if (index === -1) {
                    deviceInfo.push(data);
                  } else {
                    console.log("object already exists");
                  }
                });
            },
          });
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const syncRelations = (id) => {
    try {
      db.collection("relaciones")
        .where("usuario", "==", id)
        .get()
        .then((querySnapshot) => {
          const data2 = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          try {
            getQR(data2);
          } catch (e) {
            console.log(e);
          }
          setRelations(data2);
          syncDevices();
        });
    } catch (e) {
      console.log(e);
      console.log(id);
    }
  };

  const syncDevices = (row) => {
    try {
      db.collection("dispositivos")
        .get()
        .then((querySnapshot) => {
          const data = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setDevices(data);
          if (deviceInfo.length > 0) {
            setOpenSync(true);
          }
        });
    } catch (e) {
      console.log(e);
      syncRelations(row.id);
    }
  };
  const add = async () => {
    if (saving) return;

    try {
      setSaving(true);

      const _user = { ...user };

      delete _user.confirmPassword;

      const response = await Services.post("add-user", _user);

      if (response.data.success) {
        setMessage("Usuario guardado con éxito.");
        setOpen(false);
        setTimeout(() => {
          setMessage("");
        }, 4000);
      } else if (response.data.error.code === "auth/email-already-exists") {
        setError(
          'Error al guardar el usuario, el "Correo electrónico" está en uso.'
        );
      } else {
        setError("Error al guardar el usuario, intentelo de nuevo.");
      }
    } catch (error) {
      console.log(error);
      setError("Error al guardar el usuario, intentelo de nuevo.");
    } finally {
      setSaving(false);
    }
  };

  const update = async () => {
    if (saving) return;

    try {
      setSaving(true);

      const _user = { ...user };

      delete _user.confirmPassword;

      const response = await Services.post("update-user", _user);

      if (response.data.success) {
        setMessage("Usuario actulizado con éxito.");
        setOpen(false);
        setTimeout(() => {
          setMessage("");
        }, 4000);
      } else if (response.data.error.code === "auth/email-already-exists") {
        setError(
          'Error al editar el usuario, el "Correo electrónico" está en uso.'
        );
      } else {
        setError("Error al editar el usuario, intentelo de nuevo.");
      }
    } catch (error) {
      console.log(error);
      setError("Error al editar usuario, intentelo de nuevo");
    } finally {
      setSaving(false);
    }
  };

  const del = async () => {
    try {
      setDeleting(true);

      const response = await Services.post("delete-user", {
        id: deleteId,
      });

      if (!response.data.success) {
        setError("Error al borrar el usuario, intentelo de nuevo.");
      }
    } catch (error) {
      console.log(error);
      setError("Error al borrar el usuario, intentelo de nuevo.");
    } finally {
      setDeleteId("");
      setDeleting(false);
    }
  };

  return (
    <Container style={{ marginTop: 30, paddingBottom: 60 }}>
      <div style={{ width: "100%" }}>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography variant="h5">Usuarios</Typography>
          </Box>
          <Box>
            <IconButton
              onClick={() => {
                setOpen(true);
                setMode("add");
                setUser({
                  displayName: "",
                  email: "",
                  password: "",
                  confirmPassword: "",
                });
              }}
              style={{
                backgroundColor: "#ef4036",
                color: "white",
                float: "right",
              }}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Box>
      </div>

      <Table
        data={users}
        filter={["displayName", "email"]}
        header={header}
        paginated
        extraRows={[
          {
            prop: "registry",
            name: "Fecha de registro",
            cell: (row) => (
              <>
                {moment(Date.parse(row.fecha.toDate())).format(
                  "DD-MM-YYYY HH:mm"
                )}
              </>
            ),
          },
          {
            prop: "del",
            name: "Eliminar",
            cell: (row) => (
              <IconButton
                style={{ backgroundColor: "#de091e", color: "white" }}
                onClick={() => setDeleteId(row.id)}
              >
                <DeleteIcon />
              </IconButton>
            ),
          },
          {
            prop: "edit",
            name: "Editar",
            cell: (row) => (
              <IconButton
                style={{ backgroundColor: "#f0dc5d", color: "white" }}
                onClick={async () => {
                  setUser(row);
                  setOpen(true);
                  setMode("edit");
                }}
              >
                <EditIcon />
              </IconButton>
            ),
          },
          {
            prop: "sync",
            name: "Relacionar",
            cell: (row) => (
              <IconButton
                style={{ backgroundColor: "#03a5fc", color: "white" }}
                onClick={async () => {
                  setUser(row);
                  setOpenSync(true);
                  syncDevices(row);
                }}
              >
                <SyncAlt />
              </IconButton>
            ),
          },
        ]}
      />
      <Alert
        open={Boolean(error)}
        onClose={() => setError("")}
        message={error}
        severity="error"
      />
      <Alert
        open={Boolean(message)}
        onClose={() => setMessage("")}
        message={message}
        severity="success"
      />
      <SyncDialog
        deviceInfo={deviceInfo}
        urls={urls}
        devices={devices}
        user={user}
        openSync={openSync}
        handleSyncRelation={() => setOpenSync(false)}
        deleteUser={() => setOpenSync(false)}
        onClose={() => setOpenSync(false)}
        onAcceptDelete={deleteUser}
      />
      <DeleteDialog
        open={Boolean(deleteId)}
        onCancel={() => setDeleteId("")}
        onClose={() => setDeleteId("")}
        onAccept={del}
        loading={deleting}
      />
      <UsersDialog
        saving={saving}
        open={open}
        user={user}
        onClose={() => setOpen(false)}
        onChange={(key, value) => {
          setUser({ ...user, [key]: value });
        }}
        onErrorPasswords={(_error) => setError(_error)}
        onAccept={mode === "add" ? add : update}
        mode={mode}
      />
    </Container>
  );
};

export default UsersView;
