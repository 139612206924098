import React from "react";
import { Button, Box, Container, Grid, makeStyles } from "@material-ui/core";
import Page from "../../components/Page";
import Results from "./Results";
import Events from "./events";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useEffect,useState } from "react";
import app from "../../firebase";
import SensorReading from "./reading";
import Chart from "./chart";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));
function getNewDate(originalDate, dateOffset){
  // converting original date to milliseconds
  var originalMS = Date.parse(originalDate);
  // calculating your date offest in milliseconds
  var offsetMS = dateOffset * 1000 * 3600 * 24;
  // apply the offset millisecons to original moment
  var newDateMS = originalMS + offsetMS;
  // Convert it back to new date object and return it
  return new Date(newDateMS);
}
const Dashboard = ({ location }) => {
  const db = app.firestore();
  const [events, setEvents] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [timedata, setTimeData] = React.useState([]);
  const [devices, setDevices] = React.useState([]);
  const [device, setDevice] = React.useState({});

  const handleMin = (value) => {
    try {
      db.collection("dispositivos")
        .doc(location.state.id)
        .update({ min: Number(value) });
    } catch (e) {
      console.log(e);
    }
  };

  const handleMax = (value) => {
    try {
      db.collection("dispositivos")
        .doc(location.state.id)
        .update({ max: Number(value) });
    } catch (e) {
      console.log(e);
    }
  };
  const handleFactor = (value) => {
    try {
      db.collection("dispositivos")
        .doc(location.state.id)
        .update({ factor: Number(value) });
    } catch (e) {
      console.log(e);
    }
  };
  const handleInterval = (value) => {
    try {
      db.collection("dispositivos")
        .doc(location.state.id)
        .update({ interval: Number(value) });
    } catch (e) {
      console.log(e);
    }
  };
  const handlePercent = (info) => {
    if(info<=0){info=0;}
    if(info>=100){info=100;}
    return info;};
    const degrees_to_radians=(degrees)=>
{
  var pi = Math.PI;
  return degrees * (pi/180);
}
  const applyFactor = (device) => {
    try {
      let f=0;
      if(device.factor){
         f =device.factor;
      }else{
        f=0;
      }
      val=device.value;
      val=val+f;
      let teta=(val*180/360);
      let radians=degrees_to_radians(teta);
      let val=((1-Math.cos(radians))/2)*100;
      
      val=Math.round(val)
      val=handlePercent(val);
      return val;
    } catch (e) {
      console.log(e);
      return 0;
    }
  };
  const applyFactorEvents = (event) => {
    try {
      let f=0;
      if(device.factor){
         f =device.factor;
      }else{
        f=0;
      }
      val=event;
      val=val+f;
      let teta=(val*180/360);
      let radians=degrees_to_radians(teta);
      let val=((1-Math.cos(radians))/2)*100;
      
      val=Math.round(val)
      val=handlePercent(val);
      return val;
    } catch (e) {
      console.log(e);
      return 0;
    }
  };
  useFirestoreConnect([
    {
      collection: "dispositivos",
    },
    {
      collection: "eventos",
      orderBy: ["time", "desc"],
      limit:20,
      where: [["id_unico", "==", location.state.id]],
    },
  ]);
  const devicesSelector = useSelector(
    (state) => state.firestore.ordered.dispositivos
  );
  const eventSelector = useSelector((state) => state.firestore.ordered.eventos);
  const classes = useStyles();

  useEffect(() => {
    try {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      const todayTimestamp = app.firestore.Timestamp.fromDate(date);
      if (devicesSelector) {
        let _devices = [...devicesSelector];
        
        setDevices(_devices);
        let d = _devices.filter((a) => a.id === location.state.id);
        setDevice(d[0]);
      }
    } catch (e) {
      console.log(e);
    }
  }, [devicesSelector]);
  useEffect(() => {
    try {
      if (eventSelector) {
        let _eventos = [...eventSelector];
        let newEvents= _eventos.map((item) => ({
          ...item,
          porcentaje: applyFactorEvents(item.value)       
      }));
      
        
        console.log(newEvents);
        setEvents(newEvents);
        setData(
          _eventos.map((e) =>
            Math.round(
              ((e.value - device.min) / (device.max - device.min)) * 100
            )
          )
        );
      }
    } catch (e) {
      console.log(e);
    }
  }, [eventSelector]);
  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={3} xl={3} xs={12}>
            <SensorReading
              info={
                device && device.value
                  ? applyFactor(device)
                  : 0
              }
              lastUpdate={device &&
                device.fecha
                ? device.fecha
                : null}
            />
          </Grid>
        </Grid>

        <Box mt={3}>
          <Box height="25"></Box>
          <Grid container spacing={3}>
            <Grid item lg={8} md={8} xl={8} xs={12}>
              <Results
                handleMin={(value) => {
                  handleMin(value);
                }}
                handleMax={(value) => {
                  handleMax(value);
                }}
                handleFactor={(value) => {
                  handleFactor(value);
                }}
                handleInterval={(value) => {
                  handleInterval(value);
                }}
                data={devices.filter((a) => a.id === location.state.id)}
              />
              <Events data={events.reverse()}/>
            </Grid>
            <Grid item lg={4} md={6} xl={3} xs={12} style={{ height: 400 }}>
              <Chart data={data.slice(data.length - 20, data.length)} />
            </Grid>
          </Grid>
        </Box>
        <Box height="60"></Box>
      </Container>
    </Page>
  );
};

export default Dashboard;
