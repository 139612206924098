import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Table from "../../components/Table";
import { Box, Card, Chip, IconButton, makeStyles } from "@material-ui/core";
import NewReleases from "@material-ui/icons/NewReleases";
import { Link } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "./Dialog";
import Services from "../../services";
import DeleteDialog from "../../components/DeleteDialog";
import moment from "moment";

const header = [
  {
    prop: "version",
    name: "Versión",
  },
  {
    prop: "descripcion",
    name: "Descripción",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({
  className,
  handleSync,
  handleEdit,
  handleDelete,
  title,
  data,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openDialogDelete, setOpenDialogDelete] = React.useState(false);
  const [model, setModel] = React.useState([]);

  const update = async () => {
    try {
      let data = {
        nombre: model.nombre,
        descripcion: model.descripcion,
        precio: model.precio,
        fecha:
          model.fecha.seconds !== undefined
            ? model.fecha
            : new Date(model.fecha),
        activo: model.activo,
      };

      if (model.logo && typeof model.logo !== "string") {
        const url = await Services.uploadFile(
          "gaseras/" + model.logo.name,
          model.logo
        );
        data.logo = url;
        data.logoPath = "gaseras/" + model.logo.name;
      }

      await Services.updateDocument("gaseras", model.id, data);
    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
    }
  };

  const borrar = async () => {
    try {
      await Services.delete("firmware", model.id);
    } catch (error) {
      console.log(error);
    } finally {
      setOpenDialogDelete(false);
      setModel(false);
    }
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Table
        header={header}
        data={data}
        paginated
        filter={["version", "descripcion"]}
        extraRows={[
          {
            prop: "created_at",
            name: "Creado en",
            cell: (row) => (
              <>
                {row.fecha
                  ? moment(Date.parse(row.fecha.toDate())).format(
                      "YYYY-MM-DD HH:mm"
                    )
                  : "Sin fecha"}
              </>
            ),
          },
          {
            prop: "actions",
            name: "",
            cell: (row) => (
              <>
                <IconButton
                  style={{ backgroundColor: "#f02d1d", color: "white" }}
                  onClick={() => {
                    setOpenDialogDelete(true);
                    setModel(row);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            ),
          },
        ]}
      />
      <Dialog
        open={open}
        onAccept={update}
        onCancel={() => {
          setOpen(false);
          setModel({});
        }}
        onChange={(key, value) => {
          if (key === "image") {
            setModel({
              ...model,
              [key]: value,
              logo: URL.createObjectURL(value),
            });
          } else if (key === "activo") {
            setModel({
              ...model,
              [key]: value,
              activo: model.activo === true ? false : true,
            });
          } else {
            setModel({ ...model, [key]: value });
          }
        }}
        model={model}
        title={title}
      />
      <DeleteDialog
        open={openDialogDelete}
        onAcceptDelete={borrar}
        onCancel={() => {
          setOpenDialogDelete(false);
          setModel({});
        }}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;
