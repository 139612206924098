import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { useState, useEffect } from "react";
import axios from "axios";
import "react-dropdown/style.css";
import { makeStyles } from "@material-ui/core/styles";
import Results from "./ModalTable";
import Relations from "./RelationsTable";
import QRCode from "easyqrcodejs";
import Page from "../../components/Page";
import Services from "../../services";
import Loader from "../../components/Loader";
import DeleteDialog from "../../components/DeleteDialog";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

export default function FormDialog({
  openSync,
  onClose,
  user,
  mode,
  devices,
  deviceInfo,
}) {
  const classes = useStyles();
  const [rol, setRol] = React.useState("");
  const [device, setDevice] = React.useState({});
  const [dispositivos, setDispositvos] = React.useState([]);
  const [usuarioDispositivos, setUsuarioDispositivos] = React.useState([]);
  const [urls, setUrls] = useState([]);
  const [usuario, setUsuario] = React.useState({});

  useFirestoreConnect([{ collection: "dispositivos" }]);
  const dispositivosSelector = useSelector(
    (state) => state.firestore.ordered.dispositivos
  );
  const [openDelete, setOpenDelete] = React.useState(false);
  const code = React.createRef();

  useEffect(() => {
    setUsuario(user);

    if (dispositivosSelector && user.dispositivos) {
      const _usuarioDispositivos = dispositivosSelector.filter((d) =>
        user.dispositivos.includes(d.id)
      );

      setUsuarioDispositivos(_usuarioDispositivos);
      setDispositvos(
        dispositivosSelector.filter((d) => !user.dispositivos.includes(d.id))
      );

      if (_usuarioDispositivos.length && _usuarioDispositivos) {
        _usuarioDispositivos.forEach((d) => {
          new QRCode(code.current, {
            text: d.id,
            width: 256,
            height: 256,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
            dotScale: 1,
            onRenderingEnd: function (_, dataURL) {
              var index = urls.findIndex((x) => x == dataURL);
              if (index === -1) {
                urls.push(dataURL);
              } else {
                console.log("object already exists");
              }
            },
          });
        });
      }
    } else if (dispositivosSelector) {
      setUsuarioDispositivos([]);
      setDispositvos(dispositivosSelector);
    }
  }, [dispositivosSelector, user]);

  if (!isLoaded(dispositivosSelector)) {
    return <Loader />;
  }

  function getUnique(arr, index) {
    const unique = arr
      .map((e) => e[index])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  }

  const handleSyncRelation = async (device) => {
    try {
      const _user = { ...usuario };
      if (_user.dispositivos) {
        _user.dispositivos.push(device.id);
      } else {
        _user.dispositivos = [];
        _user.dispositivos.push(device.id);
      }

      await Services.updateDocument("usuarios", _user.id, {
        dispositivos: _user.dispositivos,
      });

      setDispositvos((dispositivos) => [
        ...dispositivos.filter((d) => d.id !== device.id),
      ]);

      const _usuarioDispositivos = [...usuarioDispositivos];
      _usuarioDispositivos.push(device);
      setUsuarioDispositivos(_usuarioDispositivos);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const deleteUser = async () => {
    try {
      const _user = { ...usuario };
      const index = _user.dispositivos.findIndex((d) => d === device.id);

      const _dispositivos = [...dispositivos];

      _user.dispositivos.splice(index, 1);
      _dispositivos.push(device);

      await Services.updateDocument("usuarios", _user.id, {
        dispositivos: _user.dispositivos,
      });

      setUsuarioDispositivos((usuarioDispositivos) => [
        ...usuarioDispositivos.filter((d) => d.id !== device.id),
      ]);

      setDispositvos(_dispositivos);
      setOpenDelete(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleComboOpen = () => {
    setComboOpen(true);
  };

  return (
    <Page className={classes.root} title="Dispositivos">
      <div
        ref={code}
        style={{
          display: "none",
          visibility: "hidden",
          maxWidth: 0,
          maxHeight: 0,
        }}
      ></div>

      <Dialog
        open={openSync}
        onClose={onClose}
        fullScreen
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Relacionar con Dispositivo
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogContent>
          {usuarioDispositivos.length >= 1 ? (
            <Button>Dispositivos Relacionados con este Usuario</Button>
          ) : null}

          <DeleteDialog
            open={openDelete}
            onCancel={() => setOpenDelete(false)}
            onClose={() => setOpenDelete(false)}
            onAcceptDelete={deleteUser}
          />
          <Relations
            data={usuarioDispositivos}
            urls={urls}
            handleEdit={(row) => {
              setMode("update");
              setOpen(true);
            }}
            handleDelete={(row) => {
              setDevice(row);

              setOpenDelete(true);
            }}
            handleSync={(row) => {
              // setUser(row);
            }}
          />
          {dispositivos.length >= 1 ? (
            <Button className={classes.button} onClick={handleComboOpen}>
              Dispositivos Disponibles
            </Button>
          ) : null}

          <Results
            data={dispositivos}
            handleEdit={(row) => {
              setMode("update");
              setOpen(true);
            }}
            handleDelete={(row) => {
              setOpenDelete(true);
              setDevice(row);

              // setUser(row);
            }}
            handleSync={(row) => {
              setDevice(row);
              handleSyncRelation(row);

              // setUser(row);
            }}
          />
        </DialogContent>
      </Dialog>
    </Page>
  );
}
