import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import Table from "../../components/Table";
import {
  Box,
  Card,
  makeStyles,
  IconButton,
  Chip,
} from "@material-ui/core";
import CheckCircle from "@material-ui/icons/CheckCircle";
import DriverEta from "@material-ui/icons/DriveEta";
import Cancel from "@material-ui/icons/Cancel";
import NewReleases from "@material-ui/icons/NewReleases";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import DeleteIcon from "@material-ui/icons/DeleteForever";

const header = [
  {
    prop: "calificacion",
    name: "Calificación",
  },

];

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({
  className,
  handleSync,
  handleEdit,
  handleDelete,
  data,
  handleRemovePedido,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Table
        header={header}
        data={data}
        extraRows={[
          {
            prop: "fecha",
            name: "Fecha de Creacion",
            cell: (row) => (
              <>
                {row.fecha
                  ? moment(Date.parse(row.fecha.toDate())).format(
                      "YYYY-MM-DD HH:mm"
                    )
                  : "Inmediato"}
              </>
            ),
          },
          {
            prop: "programado",
            name: "Fecha de Entrega",
            cell: (row) => (
              <>
                {row.programado
                  ? moment(Date.parse(row.programado.toDate())).format(
                      "YYYY-MM-DD HH:mm"
                    )
                  : "Inmediato"}
              </>
            ),
          },
          {
            prop: "litros",
            name: "Litros",
            cell: (row) => <>{row.litros.toFixed(2)} </>,
          },
          {
            prop: "precioPorLitro",
            name: "Precio por Litro",
            cell: (row) => <>$ {row.precioPorLitro} </>,
          },
          {
            prop: "metodopago",
            name: "Metodo de Pago",
            cell: (row) => (
              <>
                {row.metodopago ? 
               row.metodopago:
                  ""
                }
              </>
            ),
          },
          {
            prop: "pagado",
            name: "Pago Exitoso",
            cell: (row) => (
              <>
                {row.pagado ? 
               "Pagado":
                  "No pagado"
                }
              </>
            ),
          },
          {
            prop: "total",
            name: "Total",
            cell: (row) => <>$ {row.total} </>,
          },

          {
            prop: "estatus",
            name: "Estatus",
            cell: (row) => (
              <Box>
                {/*    {estatus[row.estatus]} */}
                {row.estatus == "NUEVO" ? (
                  <Chip
                    icon={<NewReleases style={{ color: "white" }} />}
                    size="small"
                    label="Nuevo"
                    clickable
                    style={{ color: "white", backgroundColor: "black" }}
                  />
                ) : null}
                {row.estatus == "ASIGNADO" ? (
                  <Chip
                    icon={<CompareArrowsIcon style={{ color: "white" }} />}
                    size="small"
                    label="Asignando Chofer"
                    clickable
                    style={{ color: "white", backgroundColor: "#213acc" }}
                  />
                ) : null}
                {row.estatus == "EN RUTA" ? (
                  <Chip
                    icon={<DriverEta />}
                    size="small"
                    label="Chofer en Ruta"
                    clickable
                    color="primary"
                    style={{ color: "white", backgroundColor: "#c9500e" }}
                  />
                ) : null}
                {row.estatus == "TERMINADO" ? (
                  <Chip
                    icon={<CheckCircle style={{ color: "white" }} />}
                    size="small"
                    label="Terminado"
                    style={{ color: "white", backgroundColor: "#3ecc3b" }}
                    clickable
                  />
                ) : null}
                {row.estatus == "CANCELADO" ? (
                  <Chip
                    icon={<Cancel style={{ color: "white" }} />}
                    size="small"
                    label="Cancelado"
                    style={{ color: "white", backgroundColor: "#d90b0b" }}
                    clickable
                  />
                ) : null}
              </Box>
            ),
          },
          {
            prop: "none3",
            name: "Cancelar",
            cell: (row) => (
              <Box>
                {row.estatus == "NUEVO" || row.estatus == "ASIGNADO" ? (
                  <IconButton
                    style={{ backgroundColor: "#de091e", color: "white" }}
                    onClick={() => handleRemovePedido(row)}
                  >
                    <DeleteIcon />
                  </IconButton>
                ) : null}
              </Box>
            ),
          },
        ]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;
