import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
} from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import PeopleIcon from "@material-ui/icons/PeopleOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  avatar: {
    backgroundColor: colors.green[600],
    height: 56,
    width: 56,
  },
  differenceIcon: {
    color: colors.green[900],
  },
  differenceValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1),
  },
}));

const TotalCustomers = ({ className, info, ...rest }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              INFORMACION DEL CLIENTE
            </Typography>

            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Typography style={{ color: "black" }} variant="h6">
                {" "}
                Cliente:{"   "}
              </Typography>{" "}
              <Typography style={{ color: "black" }}>
                {info.cliente.nombre}
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Typography style={{ color: "black" }} variant="h6">
                Telefono:
              </Typography>
              <Typography style={{ color: "black" }}>
                {info.cliente.telefono}
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Typography style={{ color: "black" }} variant="h6">
                Comentarios:
              </Typography>
              <Typography style={{ color: "black" }}>
                {info.comentariosPedido}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

TotalCustomers.propTypes = {
  className: PropTypes.string,
};

export default TotalCustomers;
