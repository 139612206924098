import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import Table from "../../components/Table";
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Typography,
  makeStyles,
  IconButton,
  Container,
  Chip,
} from "@material-ui/core";
import CheckCircle from "@material-ui/icons/CheckCircle";
import DriverEta from "@material-ui/icons/DriveEta";
import Cancel from "@material-ui/icons/Cancel";
import NewReleases from "@material-ui/icons/NewReleases";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import { Link } from "react-router-dom";
import ChatIcon from '@mui/icons-material/Chat';


const header = [
  {
    prop: "folio",
    name: "Folio",
  },
  {
    prop: "nombre",
    name: "Nombre",
  },

];

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const chips = {
  NUEVO: (
    <Chip
      icon={<NewReleases style={{ color: "white" }} />}
      size="small"
      label="Nuevo"
      clickable
      style={{ color: "white", backgroundColor: "black" }}
    />
  ),
};

const Results = ({
  className,
  handleSync,
  handleEdit,
  handleChat,
  handleDelete,
  data,
  onClick,
  handleOpenChat,
  chatOpen,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Card className={clsx(classes.root, className)}>
      <Table
      /* onClick={onClick}  */
        header={header}
        data={data}
        paginated
        filter={["folio", "nombre", "uid"]}
        extraRows={[
          /*     {
            prop: "cliente",
            name: "Cliente",
            cell: (row) => <> {row.cliente.nombre} </>,
          }, */

          {
            prop: "direccion",
            name: "Direccion",
            cell: (row) => (
              <>
                <Typography style={{ color: "black" }}>
                  {row.direccion.calle ? row.direccion.calle + "," : ""}
                  {row.direccion.numero ? row.direccion.numero + "," : ""}
                  {row.direccion.colonia ? row.direccion.colonia + "," : ""}
                  {row.direccion.codigopostal
                    ? row.direccion.codigopostal + ","
                    : ""}
                  {row.direccion.referencia
                    ? " " + "Referencia: " + row.direccion.referencia + ","
                    : ""}
                  {row.direccion.ciudad ? row.direccion.ciudad + "," : ""}
                </Typography>
              </>
            ),
          },
          {
            prop: "litros",
            name: "Litros",
            cell: (row) => <> {row.litros.toFixed(2)} </>,
          },
          {
            prop: "metodopago",
            name: "Metodo de Pago",
            cell: (row) => (
              <>
                {row.metodopago ? 
               row.metodopago:
                  ""
                }
              </>
            ),
          },
          {
            prop: "pagado",
            name: "Pago Exitoso",
            cell: (row) => (
              <>
                {row.pagado ? 
               "Pagado":
                  "No pagado"
                }
              </>
            ),
          },
      
          {
            prop: "total",
            name: "Total",
            cell: (row) => <>$ {row.total} </>,
          },
          {
            prop: "fecha",
            name: "Fecha",
            cell: (row) => (
              <>
                {row.fecha
                  ? moment(Date.parse(row.fecha.toDate())).format(
                      "YYYY-MM-DD HH:mm"
                    )
                  : "Sin fecha"}
              </>
            ),
          },
          {
            prop: "programado",
            name: "Entrega",
            cell: (row) => (
              <>
                {row.programado ? (
                  <Chip
                    icon={<NewReleases style={{ color: "white" }} />}
                    size="small"
                    label={moment(Date.parse(row.programado.toDate())).format(
                      "YYYY-MM-DD HH:mm"
                    )}
                    clickable
                    style={{ color: "white", backgroundColor: "#db8102" }}
                  />
                ) : (
                  "Inmediato"
                )}
              </>
            ),
          },
                  {
            prop: "chat",
            name: "Chat",
            cell: (row) => (
              <IconButton
                style={{ backgroundColor: "black", color: "white" }}
                onClick={() => handleOpenChat(row)}
              >
                <ChatIcon />
              </IconButton>
            ),
          },
       
          {
            prop: "estatus",
            name: "Estatus",
            cell: (row) => (
              <Link
                to={{
                  pathname: `/pedido`,
                  state: row,
                }}
              >
                <Box>
                  {/*    {estatus[row.estatus]} */}
                  {row.estatus == "NUEVO" ? (
                    <Chip
                      icon={<NewReleases style={{ color: "white" }} />}
                      size="small"
                      label="Nuevo"
                      clickable
                      onClick={() => onClick(row)}
                      style={{ color: "white", backgroundColor: "black" }}
                    />
                  ) : null}
                  {row.estatus == "ASIGNADO" ? (
                    <Chip
                      icon={<CompareArrowsIcon style={{ color: "white" }} />}
                      size="small"
                      label="Chofer Asignado"
                      clickable
                      onClick={() => onClick(row)}
                      style={{ color: "white", backgroundColor: "#213acc" }}
                    />
                  ) : null}
                  {row.estatus == "EN RUTA" ? (
                    <Chip
                      icon={<DriverEta />}
                      size="small"
                      label="Chofer en Ruta"
                      color="primary"
                      style={{ color: "white", backgroundColor: "#cf6d38" }}
                      clickable
                      onClick={() => onClick(row)}
                    />
                  ) : null}
                  {row.estatus == "TERMINADO" ? (
                    <Chip
                      icon={<CheckCircle style={{ color: "white" }} />}
                      size="small"
                      label="Terminado"
                      style={{ color: "white", backgroundColor: "3ecc3b" }}
                      clickable
                      onClick={() => onClick(row)}
                    />
                  ) : null}
                  {row.estatus == "CANCELADO" ? (
                    <Chip
                      icon={<Cancel style={{ color: "white" }} />}
                      size="small"
                      label="Cancelado"
                      style={{ color: "white", backgroundColor: "#d90b0b" }}
                      clickable
                      onClick={() => onClick(row)}
                    />
                  ) : null}
                </Box>
              </Link>
            ),
          },
        ]}
      />
    </Card>
  );
};

export default Results;
