import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Table from "../../components/Table";
import {
  Box,
  Card,
  makeStyles,
  IconButton,
  Chip,
} from "@material-ui/core";

import SyncAlt from "@material-ui/icons/SyncAlt";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
const header = [
  {
    prop: "nombre",
    name: "Nombre",
  },
  {
    prop: "celular",
    name: "Telefono",
  },
  {
    prop: "correo",
    name: "Correo Electrónico",
  },
  {
    prop: "gasera",
    name: "Gasera",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({
  className,
  handleSync,
  handledriver,
  handleDelete,
  data,
  data2,
  handleRemoveDriver,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Table
        header={header}
        data={data}
        paginated
        filter={["nombre", "correo", "gasera"]}
        extraRows={[
          {
            prop: "none",
            name: "Estatus",
            cell: (row) => (
              <Box>
                {row.estatus == "0" ? (
                  <Chip
                    icon={<AssignmentIndIcon style={{ color: "white" }} />}
                    size="small"
                    label="Chofer Desocupado"
                    clickable
                    style={{ color: "white", backgroundColor: "#70d134" }}
                  />
                ) : null}
                {row.estatus == "1" ? (
                  <Chip
                    icon={<EventBusyIcon style={{ color: "white" }} />}
                    size="small"
                    label="Chofer Ocupado"
                    clickable
                    style={{ color: "white", backgroundColor: "#ff3030" }}
                  />
                ) : null}
              </Box>
            ),
          },

          {
            prop: "none2",
            name: "Asignar",
            cell: (row) => (
              <Box>
                {data2.estatus == "NUEVO" ? (
                  <IconButton
                    style={{ backgroundColor: "#03a5fc", color: "white" }}
                    onClick={() => handledriver(row)}
                  >
                    <SyncAlt />
                  </IconButton>
                ) : null}
              </Box>
            ),
          },
        ]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;
