import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import Table from "../../components/Table";
import {Box, Card, Chip, IconButton, makeStyles,} from "@material-ui/core";
import NewReleases from "@material-ui/icons/NewReleases";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from './Dialog'
import Services from '../../services';
import DeleteDialog from '../../components/DeleteDialog';

const header = [
  {
    prop: "nombre",
    name: "Nombre",
  },
  {
    prop: "descripcion",
    name: "Descripcion",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({className, handleSync, handleEdit, handleDelete, title, data, ...rest}) => {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openDialogDelete, setOpenDialogDelete] = React.useState(false);
  const [model, setModel] = React.useState([]);

  const update = async () => {
    try {

      let data = {
        nombre: model.nombre,
        descripcion: model.descripcion,
        inicio: model.inicio.seconds !== undefined ? model.inicio : new Date(model.inicio),
        fin: model.fin.seconds !== undefined ? model.fin : new Date(model.fin),
        activo: model.activo,
        url: model.url !== undefined ? model.url : "",
        informacion: model.informacion !== undefined ? model.informacion : "",
      };


      if (model.imagen && typeof model.imagen !== "string" ) {
        const url = await Services.uploadFile('promociones/' + model.imagen.name, model.imagen);
        data.imagen = url;
        data.imagenPath = 'promociones/' + model.imagen.name;
      }

      console.log(model, data);

      await Services.updateDocument('promociones', model.id, data)

    } catch (error) {
      console.log(error);
    } finally {
      setModel([]);
      setOpen(false);
    }
  };

  const deleteIcon = async () => {
    try {

      /*if (model.imagenPath)
          await Services.deleteFile(model.imagenPath);*/

      await Services.delete('promociones', model.id);
    } catch (error) {
      console.log(error);
    } finally {
      setOpenDialogDelete(false);
      setModel(false);
    }
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Table
        header={header}
        data={data}
        paginated
        filter={["nombre", "descripcion", "uid"]}
        extraRows={[

          {
            prop: "inicio",
            name: "Fecha de inicio",
            cell: (row) => (
              <>
              {row.inicio ? moment(Date.parse(row.inicio.toDate())).format("DD/MM/YYYY") : "Sin fecha"}
              </>
            ),
          },
          {
            prop: "fin",
            name: "Fecha de fin",
            cell: (row) => (
              <>
              {row.fin ? moment(Date.parse(row.fin.toDate())).format("DD/MM/YYYY") : "Sin fecha"}
              </>
            ),
          },
          {
            prop: 'image',
            name: 'Imagen',
            cell: row => (
              <img height={50} src={row.imagen}/>
            )
          },
          {
            prop: "estatus",
            name: "Estatus",
            cell: (row) => (

              <Box>
                {/*    {estatus[row.estatus]} */}
                {row.activo === true ? (
                  <Chip
                    icon={<NewReleases style={{color: "white"}}/>}
                    size="small"
                    label="Activo"
                    clickable
                    style={{color: "white", backgroundColor: "green"}}
                  />
                ) : (
                  <Chip
                    icon={<NewReleases style={{color: "white"}}/>}
                    size="small"
                    label="Inactivo"
                    clickable
                    style={{color: "white", backgroundColor: "red"}}
                  />
                )}

              </Box>
            ),
          },
          {
            prop: 'actions',
            name: '',
            cell: row => (
              <>
              <IconButton
                style={{backgroundColor: "#f0ed18", color: "white"}}
                onClick={() => {
                  setOpen(true);
                  setModel(row);
                }}>
                <EditIcon/>
              </IconButton>
              <IconButton
                style={{backgroundColor: "#f02d1d", color: "white"}}
                onClick={() => {
                  setOpenDialogDelete(true);
                  setModel(row);
                }}>
                <DeleteIcon/>
              </IconButton>
              </>
            )
          }
        ]}
      />
      <Dialog
        open={open}
        onAccept={update}
        onCancel={() => {
          setOpen(false);
          setModel({});
        }}
        onChange={(key, value) => {
          if (key === 'image') {
            setModel({...model, [key]: value, imagen: value});
          }
          else if (key === 'activo') {
            setModel({...model, [key]: value, activo: model.activo === true ? false : true});
          }
          else {
            setModel({...model, [key]: value});
          }
          console.log(value);
        }}
        model={model}
        title={title}
      />
      <DeleteDialog
        open={openDialogDelete}
        onAcceptDelete={deleteIcon}
        onCancel={() => {
          setOpenDialogDelete(false);
          setModel({});
        }}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;
