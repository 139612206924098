import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import Table from "../../components/Table";
import { Card, makeStyles, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import PrintIcon from "@material-ui/icons/LocalPrintshop";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import SyncAlt from "@material-ui/icons/SyncAlt";
import VisibilityIcon from "@material-ui/icons/Visibility";

const header = [
  {
    prop: "id_unico",
    name: "ID Sensor",
  },
  {
    prop: "value",
    name: "Medición",
  },
  {
    prop: "provedor",
    name: "Provedor",
  },
  {
    prop: "tipo",
    name: "Tipo",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({
  viewDevice,
  handleSync,
  handleEdit,
  data,
  urls,
  handleDelete,
}) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handlePrint = (id) => {
    const index = data.map((d) => d.id).indexOf(id);
    const a = document.createElement("a");
    a.href = urls[index];
    a.download = "Image.png";
    a.click(); //Downloaded file
  };

  return (
    <Card style={{ padding: 15 }}>
      {data && (
        <Table
          header={header}
          data={data}
          paginated
          extraRows={[
            {
              prop: "created_at",
              name: "Última actualización",
              cell: (row) => (
                <>
                  {row.fecha
                    ? moment(Date.parse(row.fecha.toDate())).format(
                        "YYYY-MM-DD HH:mm"
                      )
                    : "Sin fecha"}
                </>
              ),
            },

            {
              prop: "view",
              name: "Detalles",
              cell: (row) => (
                <IconButton
                  style={{ color: "white", backgroundColor: "#1f7bc2" }}
                  onClick={() => viewDevice(row)}
                >
                  <VisibilityIcon />
                </IconButton>
              ),
            },
            {
              prop: "edit",
              name: "Modificar",
              cell: (row) => (
                <IconButton
                  style={{ backgroundColor: "#dede57", color: "white" }}
                  onClick={() => handleEdit(row)}
                >
                  <EditIcon />
                </IconButton>
              ),
            },
            {
              prop: "print",
              name: "Imprimir QR",
              cell: (row) => (
                <IconButton
                  style={{ backgroundColor: "#4e8aba", color: "white" }}
                  onClick={() => handlePrint(row.id)}
                >
                  <PrintIcon />
                </IconButton>
              ),
            },

            {
              prop: "none",
              name: "Eliminar",
              cell: (row) => (
                <IconButton
                  style={{ backgroundColor: "#de091e", color: "white" }}
                  onClick={() => handleDelete(row)}
                >
                  <DeleteIcon />
                </IconButton>
              ),
            },
          ]}
        />
      )}
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;
