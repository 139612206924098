import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import DragHandleIcon from "@material-ui/icons/Reorder";
import LocalActivityIcon from "@material-ui/icons/LocalActivity";
import InfoIcon from "@material-ui/icons/Info";
import PeopleAlt from "@material-ui/icons/PeopleAlt";
import ChatIcon from '@material-ui/icons/Chat';
import Devices from "@material-ui/icons/Devices";
import { Link } from "react-router-dom";
import AttachmentIcon from "@material-ui/icons/Attachment";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import firebase from "firebase/app";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { useEffect } from "react";
import { isLoaded, isEmpty } from "react-redux-firebase";
import Services from "./../../services";
import { AttachMoney, SyncAlt } from "@material-ui/icons";
import logo from "./../../white-logo.png";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
const useStyles = makeStyles({
  list: {
    width: "250px",
  },
  fullList: {
    width: "auto",
  },
  button: {
    height: "50%",
    cursor: "pointer",
    color: "#999999",
    fontSize: "40px",
  },
});

const MobileDrawer = ({ links }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [userAuth, setUserAuth] = useState();
  const [loading, setLoading] = useState(true);
  const auth = useSelector((state) => state.firebase.auth);

  useEffect(() => {
    if (isLoaded(auth) && !isEmpty(auth)) {
      const getUser = async () => {
        try {
          const user = await Services.getDocumentById("usuarios", auth.uid);

          if (user.exists) {
            setUserAuth({ ...user.data(), id: user.id });
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };

      getUser();
    }
  }, [auth]);
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };
  const canSee =
    userAuth &&
    (userAuth.rol === "administrador" || userAuth.rol === "call center");
  const dev = userAuth && userAuth.rol === "dev";
  const SideList = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={() => toggleDrawer(false)}
      onKeyDown={() => toggleDrawer(false)}
    >
      <List>
        {links.map((link) => {
          const Icon = link.icon;
          return (
            <Link style={{ alignItems: "center" }} to="/pedidos">
              <img src={logo} style={{ width: "100%", height: "auto" }} />
            </Link>
          );
        })}
      </List>
      <Divider />
      <List>
      {canSee && (
          <Link onClick={() => setOpen(false)} to="/chat">
            <ListItem button>
              <ListItemIcon>
                <ChatIcon />
              </ListItemIcon>
              <ListItemText primary="Chat" />
            </ListItem>
          </Link>
        )}
        {canSee && (
          <Link onClick={() => setOpen(false)} to="/clientes">
            <ListItem button>
              <ListItemIcon>
                <PeopleAlt />
              </ListItemIcon>
              <ListItemText primary="Clientes" />
            </ListItem>
          </Link>
        )}
        {dev && (
          <Link onClick={() => setOpen(false)} to="/firmware">
            <ListItem button>
              <ListItemIcon>
                <PeopleAlt />
              </ListItemIcon>
              <ListItemText primary="Firmware Update" />
            </ListItem>
          </Link>
        )}
        {canSee && (
          <Link onClick={() => setOpen(false)} to="/choferes">
            <ListItem button>
              <ListItemIcon>
                <DriveEtaIcon />
              </ListItemIcon>
              <ListItemText primary="Choferes" />
            </ListItem>
          </Link>
        )}
        {canSee && (
          <Link onClick={() => setOpen(false)} to="/dispositivos">
            <ListItem button>
              <ListItemIcon>
                <Devices />
              </ListItemIcon>
              <ListItemText primary="Dispositivos" />
            </ListItem>
          </Link>
        )}

        {canSee && (
          <Link onClick={() => setOpen(false)} to="/pedidos">
            <ListItem button>
              <ListItemIcon>
                <SyncAlt />
              </ListItemIcon>
              <ListItemText primary="Pedidos" />
            </ListItem>
          </Link>
        )}
        {userAuth && userAuth.rol === "administrador" && (
          <Link onClick={() => setOpen(false)} to="/precio">
            <ListItem button>
              <ListItemIcon>
                <AttachMoney />
              </ListItemIcon>
              <ListItemText primary="Precio por Litro" />
            </ListItem>
          </Link>
        )}
        {userAuth && userAuth.rol === "administrador" && (
          <Link onClick={() => setOpen(false)} to="/gaseras">
            <ListItem button>
              <ListItemIcon>
                <LocationCityIcon />
              </ListItemIcon>
              <ListItemText primary="Gaseras" />
            </ListItem>
          </Link>
        )}
        {userAuth && userAuth.rol === "administrador" && (
          <Link onClick={() => setOpen(false)} to="/promociones">
            <ListItem button>
              <ListItemIcon>
                <LocalActivityIcon />
              </ListItemIcon>
              <ListItemText primary="Promociones" />
            </ListItem>
          </Link>
        )}
        {canSee && (
          <Link onClick={() => setOpen(false)} to="/noticePrivacy">
            <ListItem button>
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText primary="Aviso de privacidad" />
            </ListItem>
          </Link>
        )}
        <Link onClick={async () => await firebase.logout()} to="/login">
          <ListItem button>
            <ListItemIcon>
              <MeetingRoomIcon />
            </ListItemIcon>
            <ListItemText primary="Cerrar sesión" />
          </ListItem>
        </Link>
      </List>
    </div>
  );

  return (
    <div>
      <DragHandleIcon className={classes.button} onClick={toggleDrawer(true)} />
      <SwipeableDrawer
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <SideList />
      </SwipeableDrawer>
    </div>
  );
};

export default MobileDrawer;
