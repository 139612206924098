import React from "react";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import MoneyIcon from "@material-ui/icons/Money";
import CheckCircle from "@material-ui/icons/CheckCircle";
import DriveEta from "@material-ui/icons/DriveEta";
import Cancel from "@material-ui/icons/Cancel";
import NewReleases from "@material-ui/icons/NewReleases";
import EditIcon from "@material-ui/icons/Edit";

import Rating from "@material-ui/lab/Rating";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  avatar: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56,
  },
  differenceIcon: {
    color: colors.red[900],
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1),
  },
}));

const Budget = ({ handleEdit, className, info, id, ...rest }) => {
  const classes = useStyles();
  let value = info.calificacion;

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            ></Typography>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Typography style={{ color: "#d92c23" }} variant="h4">
                {" "}
                Pedido:{" "}
              </Typography>{" "}
              <Typography color="textPrimary" variant="h4">
                {info.folio},{info.folio_integra}
              </Typography>
              <IconButton
                style={{ backgroundColor: "#dede57", color: "white" }}
                onClick={() => handleEdit()}
              >
                <EditIcon />
              </IconButton>
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Typography style={{ color: "#d92c23" }} variant="h5">
                {" "}
              </Typography>{" "}
            </Grid>
            <Box height="10px"></Box>

            <div align="center">
              <Box component="fieldset" mb={3} borderColor="transparent">
                <Typography align="center" component="legend">
                  Calificación{" "}
                </Typography>
                <Rating name="read-only" value={value} readOnly />
              </Box>
            </div>
            <div align="center">
              <Box component="fieldset" mb={3} borderColor="transparent">
                <Typography align="center" component="legend">
                  Comentarios del Cliente:{" "}
                </Typography>
                <Typography style={{ color: "black" }}>
                  {info.comentariosCalificacion}
                </Typography>
              </Box>
            </div>
            <div align="center">
              <Box component="fieldset" mb={3} borderColor="transparent">
                <Typography align="center" component="legend">
                  Comentarios del Chofer:{" "}
                </Typography>
                <Typography style={{ color: "black" }}>
                  {info.comentariosChofer}
                </Typography>
              </Box>
            </div>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

Budget.propTypes = {
  className: PropTypes.string,
};

export default Budget;
