import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import Table from "../../components/Table";
import Dialog from './Dialog'
import Services from '../../services';

import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import SyncAlt from "@material-ui/icons/SyncAlt";
import ImageIcon from '@material-ui/icons/Image';
const header = [
  {
    prop: "nombre",
    name: "Nombre",
  },

  {
    prop: "correo",
    name: "Email",
  },
  {
    prop: "celular",
    name: "Telefono",
  },
  {
    prop: "rfc",
    name: "rfc",
  },
  {
    prop: "gasera",
    name: "Gasera",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({
  className,
  handleSync,
  handleEdit,
  handleImage,

  handleDelete,
  data,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openDialogDelete, setOpenDialogDelete] = React.useState(false);
  const [model, setModel] = React.useState([]);
  const update = async () => {
    try {

      let data = {};

      if (model.logo && typeof model.logo !== "string" ) {
        const url = await Services.uploadFile('usuarios/' + model.logo.name, model.logo);
        data.urlImagen = url;
        data.logo=url;
        data.logoPath = 'usuarios/' + model.logo.name;
      }

      await Services.updateDocument('usuarios', model.id, data)

    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
    }
  };
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Table
        header={header}
        data={data}
        paginated
        filter={["nombre", "correo", "telefono", "rfc"]}
        extraRows={[
          /*  {
            prop: 'delete',
            name: 'Eliminar',
            cell: row => (
              <IconButton style={{backgroundColor: '#ffac33'}} onClick={() => handleDeleteSelect(row.id)}>
                <TableIconButton
                  color={'white'}
                  icon={DeleteIcon}
                />
              </IconButton>
            )
          }, */
          {
            prop: "fecha",
            name: "Creado en",
            cell: (row) => (
              <>
                {row.fecha
                  ? moment(Date.parse(row.fecha.toDate())).format(
                      "YYYY-MM-DD HH:mm"
                    )
                  : "Sin fecha"}
              </>
            ),
          },
          {
            prop: "image",
            name: "Imagen",
            cell: (row) => (
              <IconButton
              style={{backgroundColor: "blue", color: "white"}}
              onClick={() => {
                setOpen(true);
                setModel(row);
              }}
              >
                <ImageIcon />
              </IconButton>
            ),
          },
          {
            prop: "edit",
            name: "Modificar",
            cell: (row) => (
              <IconButton
                style={{ backgroundColor: "#f0dc5d", color: "white" }}
                onClick={() => handleEdit(row)}
              >
                <EditIcon />
              </IconButton>
            ),
          },
          {
            prop: "delete",
            name: "Eliminar",
            cell: (row) => (
              <IconButton
                style={{ backgroundColor: "#de091e", color: "white" }}
                onClick={() => handleDelete(row)}
              >
                <DeleteIcon />
              </IconButton>
            ),
          },
        ]}
      />
            <Dialog
        open={open}
        onAccept={update}
        onCancel={() => {
          setOpen(false);
          setModel({});
        }}
        onChange={(key, value) => {
          if (key === 'image') {
            setModel({...model, [key]: value, logo: URL.createObjectURL(value)});
          }
          else if (key === 'activo') {
            setModel({...model, [key]: value, activo: model.activo === true ? false : true});
          }
          else {
            setModel({...model, [key]: value});
          }

        }}
        model={model}
        title={"Imagen de Chofer"}
      />
    </Card>
    
  );
};

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;
