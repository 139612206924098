import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import Table from "../../components/Table";
import { Card, makeStyles, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import PrintIcon from "@material-ui/icons/LocalPrintshop";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import { SyncAlt } from "@material-ui/icons";

const header = [
  {
    prop: "id_unico",
    name: "ID Unico",
  },
  {
    prop: "value",
    name: "Valor de Sensor",
  },
  {
    prop: "provedor",
    name: "Provedor de Servicio",
  },
  {
    prop: "tipo",
    name: "Tipo de Sensor",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({ handleSync, data, urls }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handlePrint = (id) => {
    const index = data.map((d) => d.id).indexOf(id);
    const a = document.createElement("a");
    a.href = urls[index];
    a.download = "Image.png";
    a.click(); //Downloaded file
  };

  return (
    <Card style={{ padding: 0 }}>
      {data && (
        <Table
          header={header}
          data={data}
          paginated
          extraRows={[
            {
              prop: "edit",
              name: "Relacionar",
              cell: (row) => (
                <IconButton
                  style={{ backgroundColor: "#5ea3f7", color: "white" }}
                  onClick={() => handleSync(row)}
                >
                  <SyncAlt />
                </IconButton>
              ),
            },
          ]}
        />
      )}
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;
