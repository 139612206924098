import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { useState, useEffect } from "react";
import axios from "axios";
import "react-dropdown/style.css";
import { makeStyles } from "@material-ui/core/styles";
import Results from "./ModalTable";
import Relations from "./RelationsTable";
import UsersTable from "./UsersTable";
import QRCode from "easyqrcodejs";
import Page from "../../components/Page";
import Services from "../../services";
import Loader from "../../components/Loader";
import DeleteDialog from "../../components/DeleteDialog";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

export default function FormDialog({ openSync, onClose, device }) {
  const classes = useStyles();
  const [user, setUser] = React.useState({});
  const [rol, setRol] = React.useState("");
  const [usuarios, setUsuarios] = React.useState([]);
  const [dispositivos, setDispositivos] = React.useState([]);
  const [usuarioDispositivos, setUsuarioDispositivos] = React.useState([]);
  const [urls, setUrls] = useState([]);
  const [openDelete, setOpenDelete] = React.useState(false);
  const code = React.createRef();

  useFirestoreConnect([{ collection: "usuarios" }]);
  const usuariosSelector = useSelector(
    (state) => state.firestore.ordered.usuarios
  );

  useEffect(() => {

    try {
      let _usuarios = [...usuariosSelector];

      if (usuariosSelector && device.users) {
        console.log(_usuarios);
        let _usuariosDispositivos = [...usuariosSelector];

        _usuariosDispositivos = _usuarios.filter(
          (u) =>
            u.dispositivos &&
            u.dispositivos.length &&
            u.dispositivos.includes(device.id)
        );

        setUsuarioDispositivos(_usuariosDispositivos);

        _usuarios = _usuarios.filter(
          (u) =>
            u.dispositivos &&
            u.dispositivos.length &&
            !u.dispositivos.includes(device.id)
        );
        setUsuarios(_usuarios);
      } else if (usuariosSelector) {
        setUsuarioDispositivos([]);
        setUsuarios(_usuarios);
      }
    } catch (error) {
      console.log(error);
    }
  }, [usuariosSelector]);

  if (!isLoaded(usuariosSelector)) {
    return <Loader />;
  }
  const handleSyncRelation = async (user) => {
    try {
      const _user = { ...user };
      console.log(_user);
      _user.dispositivos += device.id;

      await Services.updateDocument("usuarios", _user.id, {
        dispositivos: _user.dispositivos,
      });

      setUsuarios(
        _usuarios.filter(
          (u) => u.dispositivos && !u.dispositivos.includes(device.id)
        )
      );
      const _usuarioDispositivos = [...usuarioDispositivos];
      console.log(_usuarioDispositivos);
      _usuarioDispositivos.push(_user);
      setUsuarioDispositivos(_usuarioDispositivos);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const deleteUser = async () => {
    try {
      const _user = { ...user };
      const index = _user.dispositivos.findIndex((d) => d === device.id);
      const _dispositivos = [...dispositivos];

      _user.dispositivos.splice(index, 1);
      _dispositivos.push(device);

      await Services.updateDocument("usuarios", _user.id, {
        dispositivos: _user.dispositivos,
      });
      let _usuariosDispositivos = _user._dispositivos;

      _usuariosDispositivos = _usuarios.filter((u) =>
        u.dispositivos.includes(device.id)
      );
      _usuarios = _usuarios.filter((u) => !u.dispositivos.includes(device.id));

      setUsuarioDispositivos(_usuariosDispositivos);

      setUsuarios(_usuarios);
    } catch (e) {
      console.log(e);
      setOpenDelete(false);
    }
    setOpenDelete(false);
  };
  return (
    <Page className={classes.root} title="Dispositivos">
      <div
        ref={code}
        style={{
          display: "none",
          visibility: "hidden",
          maxWidth: 0,
          maxHeight: 0,
        }}
      ></div>

      <Dialog
        open={openSync}
        onClose={onClose}
        fullScreen
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Relacionar con Usuario
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogContent>
          {usuarioDispositivos.length >= 1 ? (
            <Button>Usuarios Relacionados con este Dispositivo</Button>
          ) : null}

          <DeleteDialog
            open={openDelete}
            onCancel={() => setOpenDelete(false)}
            onClose={() => setOpenDelete(false)}
            onAcceptDelete={deleteUser}
          />
          <Relations
            data={usuarioDispositivos}
            handleEdit={(row) => {
              setMode("update");
              setOpen(true);
            }}
            handleDelete={(row) => {
              setUser(row);

              setOpenDelete(true);
            }}
          />
          {usuarios.length >= 1 ? (
            <Button className={classes.button}>Usuarios Disponibles</Button>
          ) : null}

          <UsersTable
            data={usuariosSelector}
            handleEdit={(row) => {
              setMode("update");
              setOpen(true);
            }}
            handleDelete={(row) => {
              setOpenDelete(true);

              // setUser(row);
            }}
            handleSync={(row) => {
              handleSyncRelation(row);

              // setUser(row);
            }}
          />
        </DialogContent>
      </Dialog>
    </Page>
  );
}
