import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAvi87qo8gI6TnzttaE8W7HwikvgEuLnME",
  authDomain: "edesarrollos-iot.firebaseapp.com",
  databaseURL: "https://edesarrollos-iot.firebaseio.com",
  projectId: "edesarrollos-iot",
  storageBucket: "edesarrollos-iot.appspot.com",
  messagingSenderId: "1091740287835",
  appId: "1:1091740287835:web:bc089898b4998ab4aa507e",
  measurementId: "G-0T4C784MRG",
};
firebase.initializeApp(firebaseConfig);

firebase.firestore();

export default firebase;
