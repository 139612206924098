import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import Table from "../../components/Table";
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import SyncAlt from "@material-ui/icons/SyncAlt";

const header = [
  {
    prop: "nombre",
    name: "Nombre",
  },

  {
    prop: "correo",
    name: "Email",
  },
  {
    prop: "celular",
    name: "Telefono",
  },
  {
    prop: "rfc",
    name: "rfc",
  },
  {
    prop: "gasera",
    name: "Gasera",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({
  className,
  handleSync,
  handleEdit,
  handleDelete,
  data,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Table
        header={header}
        data={data}
        paginated
        filter={["nombre", "correo", "telefono", "rfc"]}
        extraRows={[
          /*  {
            prop: 'delete',
            name: 'Eliminar',
            cell: row => (
              <IconButton style={{backgroundColor: '#ffac33'}} onClick={() => handleDeleteSelect(row.id)}>
                <TableIconButton
                  color={'white'}
                  icon={DeleteIcon}
                />
              </IconButton>
            )
          }, */
          {
            prop: "fecha",
            name: "Creado en",
            cell: (row) => (
              <>
                {row.fecha
                  ? moment(Date.parse(row.fecha.toDate())).format(
                      "YYYY-MM-DD HH:mm"
                    )
                  : "Sin fecha"}
              </>
            ),
          },
          {
            prop: "sync",
            name: "Relacionar",
            cell: (row) => (
              <IconButton
                style={{ backgroundColor: "#03a5fc", color: "white" }}
                onClick={() => handleSync(row)}
              >
                <SyncAlt />
              </IconButton>
            ),
          },
          {
            prop: "edit",
            name: "Modificar",
            cell: (row) => (
              <IconButton
                style={{ backgroundColor: "#f0dc5d", color: "white" }}
                onClick={() => handleEdit(row)}
              >
                <EditIcon />
              </IconButton>
            ),
          },

          {
            prop: "delete",
            name: "Eliminar",
            cell: (row) => (
              <IconButton
                style={{ backgroundColor: "#de091e", color: "white" }}
                onClick={() => handleDelete(row)}
              >
                <DeleteIcon />
              </IconButton>
            ),
          },
        ]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;
