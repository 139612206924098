import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import MoneyIcon from "@material-ui/icons/Money";
import DriveEta from "@material-ui/icons/DriveEta";
import { Link } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import { Checkbox, IconButton } from "@material-ui/core";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";
import NewReleases from "@material-ui/icons/NewReleases";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import ListIcon from "@material-ui/icons/List";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  avatar: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56,
  },
  differenceIcon: {
    color: colors.red[900],
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1),
  },
}));

const Budget = ({ title, info, data, filter, className, ...rest }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              {title}
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {data}
            </Typography>
          </Grid>
          <Grid item>
            {info == "NUEVO" ? (
              <IconButton
                style={{ backgroundColor: "#2a3d6b", color: "white" }}
                onClick={() => filter()}
              >
                <NewReleases />
              </IconButton>
            ) : null}
            {info == "ASIGNADO" ? (
              <IconButton
                style={{ backgroundColor: "#213acc", color: "white" }}
                onClick={() => filter()}
              >
                <CompareArrowsIcon />
              </IconButton>
            ) : null}
            {info == "EN RUTA" ? (
              <IconButton
                style={{ backgroundColor: "#c9500e", color: "white" }}
                onClick={() => filter()}
              >
                <DriveEta />
              </IconButton>
            ) : null}
            {info == "TERMINADO" ? (
              <IconButton
                style={{ backgroundColor: "#3ecc3b", color: "white" }}
                onClick={() => filter()}
              >
                <CheckCircle />
              </IconButton>
            ) : null}{" "}
            {info == "CANCELADO" ? (
              <IconButton
                style={{ backgroundColor: "#c93030", color: "white" }}
                onClick={() => filter()}
              >
                <DeleteIcon />
              </IconButton>
            ) : null}
            {info == "todos" ? (
              <IconButton
                style={{ backgroundColor: "#2a3d6b", color: "white" }}
                onClick={() => filter()}
              >
                <ListIcon />
              </IconButton>
            ) : null}
          </Grid>
        </Grid>
        {/*         <Box mt={2} display="flex" alignItems="center">
          <ArrowDownwardIcon className={classes.differenceIcon} />
          <Typography className={classes.differenceValue} variant="body2">
            12%
          </Typography>
          <Typography color="textSecondary" variant="caption">
            Desde el mes pasado
          </Typography>
        </Box> */}
      </CardContent>
    </Card>
  );
};

Budget.propTypes = {
  className: PropTypes.string,
};

export default Budget;
