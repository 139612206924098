import React from "react";
import "./styles.css";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import theme from "./components/Theme";
import { ThemeProvider } from "@material-ui/core/styles";
import Header from "./components/Header";
import Footer from "./components/Footer";
import RouteAuth from "./components/RouteAuth";
import { useSelector } from "react-redux";
import { isLoaded, isEmpty } from "react-redux-firebase";

import {
  HomeView,
  LogInView,
  WebView,
  NoticePrivacy,
  AdminView,
} from "./views";

import DashboardView from "./views/DashboardView";
import CustomerListView from "./views/CustomerListView";
import OrderListView from "./views/OrderListView";
import DeviceListView from "./views/DeviceListView";
import ChoferListView from "./views/ChoferListView";
import GaseraListView from "./views/GaseraListView";
import FirmwareView from "./views/FirmwareView";
import PrecioPorLitroView from "./views/PrecioPorLitroView";
import "react-notifications-component/dist/theme.css";
import app from "./firebase";
import PromotionListView from "./views/PromotionListView";
import OrderView from "./views/OrderView";
import { useEffect, useState } from "react";
import Services from "./services/index";
import DeviceView from "./views/DeviceView";
import ReactNotifications from "react-notifications-component";
import { store } from "react-notifications-component";
import boopSfx from "./sounds/notification_decorative-01.wav";
import useSound from "use-sound";
import ChatsPage from "./views/ChatView";
let notifiedMessages=[];

let notifiedFolios=[];

var originalDate = new Date();
var date2= getNewDate(originalDate, -3) 

const time=app.firestore.Timestamp.fromDate(date2);
const date = new Date();
function getNewDate(originalDate, dateOffset){
  // converting original date to milliseconds
  var originalMS = Date.parse(originalDate);
  // calculating your date offest in milliseconds
  var offsetMS = dateOffset * 1000 * 3600 * 24;
  // apply the offset millisecons to original moment
  var newDateMS = originalMS + offsetMS;
  // Convert it back to new date object and return it
  return new Date(newDateMS);
}
const containerStyle = {
  backgroundColor: "#fdfdfd",
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "fixed",
  backgroundPosition: "center",
  minHeight: "100vh",
};

const bodyStyles = {
  margin: 0,
  padding: 0,
};

export default () => {
  const db = app.firestore();
  const [play] = useSound(boopSfx);
  const [userAuth, setUserAuth] = useState();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState();
  const [deviceIds, setDevices] = useState();

  const [pedidosLoaded, setPedidosLoaded] = useState(false);
  const [messagesLoaded, setMessagesLoaded] = useState(false);

  const [pedidosLength, setpedidosLength] = useState(0);
  const [soundAlert, setAlert] = useState();



  const [pedidoNuevo2, setPedidoNuevo2] = React.useState([]);
  const auth = useSelector((state) => state.firebase.auth);

  date.setHours(0, 0, 0, 0);
  const todayTimestamp = app.firestore.Timestamp.fromDate(date);

  const docs = db.collection('pedidos').where("estatus","==","NUEVO").where("fecha",">",time);
  const newMessages = db.collection('mensajes').where("visto","==",0).where("tipo","==",1).limit(50);
  useEffect(()=>{
    play();
  },[soundAlert])


  useEffect(() => {
    if(isLoaded(auth) && !isEmpty(auth)){
      newMessages.onSnapshot((snapshot) => {
          snapshot.docs.forEach((doc) => {
            if(notifiedMessages.includes(doc.data())){
              console.log("repeat")
            }else{
          console.log(doc.data());
          doc.ref.set({visto:1},{merge:true});
if(doc.data().sala.tipo=="pedido"){
  store.addNotification({
    title: "Cliente: " + doc.data().nombreUsuario ,
    message: "Mensaje: "+doc.data().mensaje,
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 10000000,
      onScreen: false,
    },
  });

}else{
  store.addNotification({
    title: "Operador: " + doc.data().nombreUsuario ,
    message: "Mensaje: "+doc.data().mensaje,
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 10000000,
      onScreen: false,
    },
  });

}


            play();
            notifiedMessages.push(doc.data());
          }
        }
          );
        });}
  }, [auth]);
  useEffect(()=>{
    if(isLoaded(auth) && !isEmpty(auth)){

      const observer = docs.onSnapshot(docSnapshot => {
        console.log(`Received doc snapshot: ${docSnapshot}`);
      docSnapshot.docs.forEach(element => {
        if(notifiedFolios.includes(element.data().folio)){
          console.log("repeat")
        }else{
        
          store.addNotification({
            title: "Pedido de " + element.data().nombre,
            message: "Folio:" + element.data().folio,
            type: "warning",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 10000000,
              onScreen: false,
            },
          });
        notifiedFolios.push(element.data().folio);
        setAlert(element.data());
        }
      });
        // ...
      }, err => {
        console.log(`Encountered error: ${err}`);
      });
    }

  }
  ,[auth])
  useEffect(() => {
    try {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      const todayTimestamp = app.firestore.Timestamp.fromDate(date);
      db.collection('dispositivos').where('value','<',90).onSnapshot((snapshot) => {
        const results = [];
        snapshot.forEach((doc) => results.push({ ...doc.data(), id: doc.id }));
        const result = results.filter(
          (device) => device.fecha < todayTimestamp
        );
        const Ids = result.map((device) => device.id);  
        db.collection('usuarios').where('dispositivos', 'array-contains-any',
        Ids).onSnapshot((snapshot) => {
          const postData = [];
          snapshot.forEach((doc) => postData.push({ ...doc.data(), id: doc.id }));
/*           if (postData.length >= 1) {
            postData.map((p) =>
              store.addNotification({
                title: "Cliente: " + p.nombre,
                message: "Nivel Critico de Gas",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 10000000,
                  onScreen: false,
                },
              })
            );
            play();
          } */
        });
      });
    } catch (e) {
      console.log(e);
    }
  }, []);


  useEffect(() => {
    if (isLoaded(auth) && !isEmpty(auth)) {
      const getUser = async () => {
        try {
          const user = await Services.getDocumentById("usuarios", auth.uid);

          if (user.exists) {
            setUserAuth({ ...user.data(), id: user.id });
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };

      getUser();
    }
  }, [auth]);
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isTokenFound, setTokenFound] = useState(false);

  if (!isLoaded(auth)) return null;
  const canSee =
    userAuth &&
    (userAuth.rol === "administrador" || userAuth.rol === "call center");
  return (
    <div style={containerStyle}>
      <ReactNotifications />

      <ThemeProvider theme={theme}>
        <Router>
          {isEmpty(auth) && <Redirect to="/login" />}
          {!isEmpty(auth) && !loading ? <Header hideHeaderLinks /> : null}
          <div style={!isEmpty(auth) && !loading ? bodyStyles : null}>
            <Switch>
              <RouteAuth exact path="/" component={OrderListView} />

              <RouteAuth exact path="/login" component={LogInView} />
              <RouteAuth exact path="/admin" component={AdminView} />

              {
                <RouteAuth
                  exact
                  path="/promociones"
                  component={PromotionListView}
                />
              }
              {<RouteAuth exact path="/firmware" component={FirmwareView} />}
              {userAuth && userAuth.rol === "administrador" && (
                <RouteAuth exact path="/gaseras" component={GaseraListView} />
              )}
              {userAuth && userAuth.rol === "administrador" && (
                <RouteAuth
                  exact
                  path="/precio"
                  component={PrecioPorLitroView}
                />
              )}
              {canSee && (
                <RouteAuth
                  exact
                  path="/noticePrivacy"
                  component={NoticePrivacy}
                />
              )}
              
              {canSee && (
                <>
                 <RouteAuth exact path="/chat" component={ChatsPage} />
                  <RouteAuth
                    exact
                    path="/clientes"
                    component={CustomerListView}
                  />
                  <RouteAuth
                    exact
                    path="/choferes"
                    component={ChoferListView}
                  />

                  <RouteAuth exact path="/pedidos" component={OrderListView} />

                  <RouteAuth exact path="/pedido" component={OrderView} />
                  <RouteAuth exact path="/device" component={DeviceView} />

                  <RouteAuth
                    exact
                    path="/dispositivos"
                    component={DeviceListView}
                  />
                </>
              )}

              <RouteAuth exact path="/web" component={WebView} />
            </Switch>
          </div>
          {!isEmpty(auth) && !loading ? <Footer /> : null}
        </Router>
      </ThemeProvider>
    </div>
  );
};
