import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  avatar: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56,
  },
  differenceIcon: {
    color: colors.red[900],
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const Budget = ({ handleRemoveEvent, data, filter, className, ...rest }) => {
  const classes = useStyles();

  const options = {
    yAxis: {
      title: {
        text: 'Contendio de Tanque'
      }
    },
      xAxis: {
      title: {
        text: 'Tiempo de Reporte'
      }
    },
    chart: {
      type: "spline",
    },

    title: {
      text: "Lecturas de Nivel",
      
    },
    series: [
      {
        name: 'Medición',
        data: data,
      },
    ],
  };
  return (
    <Card>
      <CardContent>
        <div align="center">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      </CardContent>
    </Card>
  );
};

export default Budget;
