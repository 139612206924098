import { createTheme } from '@material-ui/core/styles';
import { esES } from '@material-ui/core/locale';

export default createTheme({
    palette: {
      primary: {
        main: '#61810e'
      },
      secondary: {
        main: '#ffc107'
      }
    },
    overrides: {
      MuiButton: {
        containedSecondary: {
          color: '#ffffff',
        },
      }
    }
},esES);