import React, { useCallback, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Crop from "../../components/Crop";

const HomeDialogView = ({
  open,
  title,
  onAccept,
  onCancel,
  onChange,
  model,
  saving,
}) => {
  const { logo = "" } = model;
  const [errors, setErrors] = useState([]);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({
    width: 900,
    height: 900,
  });

  const handleAccept = () => {
    let _errors = [];

    if (!model.descripcion) {
      _errors.push("precio");
    }

    onAccept();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Grid container>
          <TextField
            type="number"
            error={errors.includes("precio")}
            variant="outlined"
            value={model.precio}
            fullWidth
            onChange={(event) => onChange("precio", event.target.value)}
            helperText={"Precio"}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          style={{ color: "white", backgroundColor: "#232e5c" }}
        >
          Cancelar
        </Button>
        {saving ? (
          <CircularProgress style={{ color: "#232e5c" }} />
        ) : (
          <Button
            onClick={handleAccept}
            style={{ color: "white", backgroundColor: "#232e5c" }}
            autoFocus
          >
            Aceptar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default HomeDialogView;
