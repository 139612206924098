import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import appStore from "./../../app_store.png";
import googlePlay from "./../../google_play.png";

const WebView = () => {
  return (
    <div>
      <Container style={{ marginTop: 30, paddingBottom: "100px" }}>
        <Typography></Typography>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={2} md={1}></Grid>
          <Grid item xs={10} md={4}>
            <a href="https://apps.apple.com/mx/app/eco-red/id1517531122">
              <img
                src={appStore}
                style={{ width: "300px", marginTop: 20 }}
              ></img>
            </a>
          </Grid>
          <Grid item xs={2} md={3}></Grid>
          <Grid item xs={10} md={3}>
            <a href="https://play.google.com/store/apps/details?id=mx.ecred.app">
              <img src={googlePlay} style={{ width: "300px" }}></img>
            </a>
          </Grid>
          <Grid item xs={12} md={1}></Grid>
        </Grid>

        <br />
        <Typography>Blvd. Morelos 307, planta baja</Typography>
        <Typography>edificio H, Col. Militar XV.</Typography>
        <Typography>C.P. 83145 Hermosillo, Sonora.</Typography>
        <br />
      </Container>
    </div>
  );
};

export default WebView;
