import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Table from "../../components/Table";
import { Card, makeStyles, IconButton, Box, Chip } from "@material-ui/core";
import NewReleases from "@material-ui/icons/NewReleases";
import PowerIcon from "@material-ui/icons/Power";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";

const header = [
  {
    prop: "id_unico",
    name: "ID Sensor",
  },
  {
    prop: "value",
    name: "Lectura Origen",
  },
  {
    prop: "porcentaje",
    name: "Porcentaje",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({ data, handleMin, handleMax }) => {
  return (
    <Card style={{ padding: 15 }}>
      {data && (
        <Table
          header={header}
          data={data}
          paginated
          extraRows={[
            {
              prop: "porcentaje",
              name: "Porcentaje",
              cell: (row) => (
                <>
                  {row.porcentaje
                    ? porcentaje
                    : "Sin porcentaje"}
                </>
              ),
            },
            {
              prop: "time",
              name: "Fecha",
              cell: (row) => (
                <>
                  {row.time
                    ? moment(Date.parse(row.time.toDate())).format(
                        "YYYY-MM-DD HH:mm"
                      )
                    : "Sin fecha"}
                </>
              ),
            },
          ]}
        />
      )}
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;
