import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Table from "../../components/Table";
import { Card, makeStyles, IconButton, Box, Chip } from "@material-ui/core";
import PowerIcon from "@material-ui/icons/Power";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import CheckCircle from "@material-ui/icons/CheckCircle";
import DriverEta from "@material-ui/icons/DriveEta";
import Cancel from "@material-ui/icons/Cancel";
import NewReleases from "@material-ui/icons/NewReleases";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import { Link } from "react-router-dom";
const header = [
   {
     prop: "id_unico",
     name: "ID Sensor",
   },
   {
     prop: "value",
     name: "Lectura Origen",
    },
  ];

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const chips = {
  NUEVO: (
    <Chip
      icon={<NewReleases style={{ color: "white" }} />}
      size="small"
      label="Nuevo"
      clickable
      style={{ color: "white", backgroundColor: "black" }}
    />
  ),
};
const degrees_to_radians=(degrees)=>
{
  var pi = Math.PI;
  return degrees * (pi/180);
}
const handlePercent = (info) => {
  if(info<=0){info=0;}
  if(info>=100){info=100;}
  return info;};
  const applyFactor = (device) => {
    try {
      let f=0;
      if(device.factor){
         f =device.factor;
      }else{
        f=0;
      }
      val=device.value;
      val=val+f;
      let teta=(val*180/360);
      let radians=degrees_to_radians(teta);
      let val=((1-Math.cos(radians))/2)*100;
      
      val=Math.round(val)
      val=handlePercent(val);
      console.log(val);
      return val;
    } catch (e) {
      console.log(e);
      return 0;
    }
  };


const Results = ({ data, handleMin, handleMax,handleFactor,handleInterval }) => {
  const handlePercent = (info) => {
    if(info<=0){info=0;}
    if(info>=100){info=100;}
    return info;};

 return (
   <Card style={{ padding: 15 }}>
     {data && (
       <Table
         header={header}
         data={data}
         extraRows={[
           {
             prop: "percentage",
             name: "Lectura %",
             cell: (row) => (
               <>
                 {applyFactor(row)}
               </>
             ),
           },
    /*        {
             prop: "min",
             name: "Calibración Mínima ",
             cell: (row) => (
               <TextField
                 autoFocus
                 margin="dense"
                 id="min"
                 label="Minimum"
                 type="number"
                 fullWidth
                 value={row.min || ""}
                 onChange={(event) => handleMin(event.target.value)}
               />
             ),
           },
           {
             prop: "max",
             name: "Calibración Máxima",
             cell: (row) => (
               <TextField
                 autoFocus
                 margin="dense"
                 id="max"
                 label="Maximum "
                 type="number"
                 fullWidth
                 value={row.max || ""}
                 onChange={(event) => handleMax(event.target.value)}
               />
             ),
           }, */
           {
            prop: "factor",
            name: "Factor corrección",
            cell: (row) => (
              <TextField
                autoFocus
                margin="dense"
                id="factor"
                label="Factor corrección"
                type="number"
                fullWidth
                value={row.factor || ""}
                onChange={(event) => handleFactor(event.target.value)}
              />
            ),
          },
          {
            prop: "intervalo",
            name: "Intervalo de Reportes (H)",
            cell: (row) => (
              <TextField
                autoFocus
                margin="dense"
                id="factor"
                label="Intervalo"
                type="number"
                fullWidth
                value={row.interval || ""}
                onChange={(event) => handleInterval(event.target.value)}
              />
            ),
          },
           {
             prop: "time",
             name: "Última actualización",
             cell: (row) => (
               <>
                 {row.fecha
                   ? moment(Date.parse(row.fecha.toDate())).format(
                       "YYYY-MM-DD HH:mm"
                     )
                   : "Sin fecha"}
               </>
             ),
           },
         ]}
       />
     )}
   </Card>
  );
};

export default Results;
