import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useCallback, useState, useEffect } from "react";
import axios from "axios";
import app from "../../firebase";

const db = app.firestore();

export default function FormDialog({
  open,
  setOpen,
  onChange,
  pedido,
  mode,
  id,
}) {
  const handleAccept = async () => {
    if (!pedido.folio_integra) {
      alert("todos los campos son requeridos");
      return;
    }
    if (mode == "update") {
      console.log(pedido);

      pedido.modificado = app.firestore.Timestamp.now();
      db.collection("pedidos")
        .doc(id)
        .update({ folio_integra: pedido.folio_integra });
      setOpen();

      /*      const url =
        "https://us-central1-edesarrollos-iot.cloudfunctions.net/modificar-columna";

      const respo = await axios.post(url, {
        uid: pedido.id,
        id_unico: pedido.id_unico,
        provedor: pedido.provedor,
        tipo: pedido.tipo,
        modificar: "dispositivo",
      });
      console.log("res:", respo.data); */
    }

    setOpen();
  };

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Modificar Folio</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="folio_integra"
          label="Folio Integra"
          type="text"
          fullWidth
          value={pedido.folio_integra || ""}
          onChange={(event) => onChange("folio_integra", event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen()} color="primary">
          Cancelar
        </Button>
        {mode === "add" && (
          <Button onClick={handleAccept} color="primary">
            Aggregar
          </Button>
        )}
        {mode === "update" && (
          <Button onClick={handleAccept} color="primary">
            Modificar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
