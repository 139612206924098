import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useCallback, useState, useEffect } from "react";
import axios from "axios";
import Services from "../../services";

import app from "../../firebase";
const db = app.firestore();

export default function FormDialog({
  open,
  setOpen,
  firmware,
  onAccept,
  onChange,
}) {
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    module.firmware = selectedFile;
  };
  const add = async () => {
    setOpen();
    try {
      let data = {
        descripcion: firmware.descripcion,
        version: firmware.version,
        fecha: new Date(),
      };

      const url = await Services.uploadFile(
        "firmware/" + firmware.version + ".bin",
        selectedFile
      );
      data.firmware = url;

      await Services.addDocument("firmware", data);
    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
    }
  };

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Agregar Nuevo Firmware</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="version"
          label="Version"
          type="text"
          fullWidth
          value={firmware.version || ""}
          onChange={(event) => onChange("version", event.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="descripcion"
          label="Descripcion"
          type="text"
          fullWidth
          value={firmware.descripcion || ""}
          onChange={(event) => onChange("descripcion", event.target.value)}
        />

        <input type="file" name="file" onChange={changeHandler} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen()} color="primary">
          Cancelar
        </Button>
        <Button onClick={add} color="primary">
          Aggregar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
