import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Table from "../../components/Table";
import { Box, Card, Chip, IconButton, makeStyles } from "@material-ui/core";
import NewReleases from "@material-ui/icons/NewReleases";
import { Link } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "./Dialog";
import Services from "../../services";
import DeleteDialog from "../../components/DeleteDialog";

const header = [];

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({
  className,
  handleSync,
  handleEdit,
  handleDelete,
  title,
  data,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openDialogDelete, setOpenDialogDelete] = React.useState(false);
  const [model, setModel] = React.useState([]);

  const update = async () => {
    try {
      let data = {
        precio: model.precio,
      };

      await Services.updateDocument("precios", model.id, data);
    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
    }
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Table
        header={header}
        data={data}
        paginated
        extraRows={[
          {
            prop: "precioPorLitro",
            name: "Precio por Litro",
            cell: (row) => <>$ {Number.parseFloat(row.precio).toFixed(2)} </>,
          },

          {
            prop: "actions",
            name: "Editar",
            cell: (row) => (
              <>
                <IconButton
                  style={{ backgroundColor: "#f0ed18", color: "white" }}
                  onClick={() => {
                    setOpen(true);
                    setModel(row);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </>
            ),
          },
        ]}
      />
      <Dialog
        open={open}
        onAccept={update}
        onCancel={() => {
          setOpen(false);
          setModel({});
        }}
        onChange={(key, value) => {
          if (key === "image") {
            setModel({
              ...model,
              [key]: value,
              logo: URL.createObjectURL(value),
            });
          } else if (key === "activo") {
            setModel({
              ...model,
              [key]: value,
              activo: model.activo === true ? false : true,
            });
          } else {
            setModel({ ...model, [key]: value });
          }
        }}
        model={model}
        title={title}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;
