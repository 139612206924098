import React from 'react';
import Paper from '@material-ui/core/Paper';
import {  
  TextField,
  InputAdornment
} from "@material-ui/core";
import { Search as SearchIcon } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';

const Searchbox = ({ onChange, placeholder, className }) => {
  var date = new Date();
  var month=date.getMonth()+1;
  if(month>=10){
    var formatedDate = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`

  }else{
    var formatedDate = `${date.getFullYear()}-0${date.getMonth()+1}-${date.getDate()}`

  }
  return (
    <Paper className={`${className} paper`} style={{ padding: 10, margin: '20px 0 20px 0' }}>
       <Grid container spacing={2}>
       <Grid item xs={8}>

      <TextField 
        fullWidth
        type="search" 
        variant="outlined"
        placeholder={placeholder}
        onChange={(event) => onChange("text", event.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}/>
        </Grid>
         <Grid item xs={2}>

        <TextField
        onChange={(event) => onChange("startdate", event.target.value)}
        id="startdate"
        label="Inicio"
        type="date"
        defaultValue={formatedDate}
        InputLabelProps={{
          shrink: true,
        }}
      />

          </Grid> 
          <Grid item xs={2}>
        <TextField
        onChange={(event) => onChange("enddate", event.target.value)}
        id="enddate"
        label="Fin"
        type="date"
        defaultValue={formatedDate}        
        InputLabelProps={{
          shrink: true,
        }}
      />
          </Grid> 
          </Grid>
    </Paper>
  );
};

export default Searchbox;