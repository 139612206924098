import React, { useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const validatecorreo = (correo) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    correo.toLowerCase()
  );
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
const UsersDialogView = ({
  open,
  onClose,
  onAccept,
  mode,
  user = {},
  onChange,
  saving,
  onErrorPasswords,
  gaseras,
}) => {
  const [errors, setErrors] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [gasera, setGasera] = React.useState("");
  const [openCombo, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  if (!user) return null;

  const handleAccept = () => {
    if (saving) return;

    let _errors = [];

    if (!validatecorreo(user.correo)) {
      _errors.push("correo");
    }

    if (
      (user.password && mode === "edit") ||
      (user.confirmPassword && mode === "edit") ||
      mode === "add"
    ) {
      if (user.password.length < 7 || user.confirmPassword.length < 7) {
        _errors.push("passwords");
        onErrorPasswords("La contraseña debe tener un mínimo de 8 caracteres.");
      }

      if (user.password !== user.confirmPassword) {
        _errors.push("passwords");
        onErrorPasswords("Las contraseñas no coinciden.");
      }
    }

    if (!user.nombre) {
      _errors.push("nombre");
    }

    setErrors(_errors);

    if (_errors.length) return;

    onAccept();
  };

  return (
    <Dialog maxWidth="md" open={open}>
      <DialogTitle>
        {mode === "add" ? "Agregar usuario" : "Editar usuario"}
      </DialogTitle>
      <Grid item md={12} xs={12} sm={12}>
        <div>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-controlled-open-select-label">
              gasera
            </InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="gasera"
              open={openCombo}
              onClose={handleClose}
              onOpen={handleOpen}
              value={user.gasera}
              onChange={(event) => onChange("gasera", event.target.value)}
            >
              <MenuItem value={user.gasera}>
                <em>{user.gasera}</em>
              </MenuItem>

              {gaseras.map((g) => (
                <MenuItem value={g.nombre}>{g.nombre}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Grid>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12} sm={12}>
            <TextField
              error={errors.includes("nombre")}
              fullWidth
              type="text"
              label="Nombre"
              variant="outlined"
              value={user.nombre}
              onChange={(event) => onChange("nombre", event.target.value)}
              helperText={'Favor de llenar el campo "Nombre".'}
            />
          </Grid>

          <Grid item md={12} xs={12} sm={12}>
            <TextField
              autoFocus
              margin="dense"
              id="celular"
              label="Celular"
              type="text"
              variant="outlined"
              fullWidth
              inputProps={{
                autoComplete: "new-password",
              }}
              value={user.celular || ""}
              onChange={(event) => onChange("celular", event.target.value)}
              helperText={'Favor de llenar el campo "Celular".'}
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <TextField
              autoFocus
              margin="dense"
              id="rfc"
              label="RFC"
              type="text"
              variant="outlined"
              fullWidth
              inputProps={{
                autoComplete: "new-password",
              }}
              value={user.rfc || ""}
              onChange={(event) => onChange("rfc", event.target.value)}
              helperText={'Favor de llenar el "RFC".'}
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <TextField
              error={errors.includes("correo")}
              fullWidth
              type="text"
              label="Correo electrónico"
              variant="outlined"
              value={user.correo}
              onChange={(event) => onChange("correo", event.target.value)}
              helperText={'Favor de escribir un "Corre electrónico" válido.'}
              inputProps={{ autoComplete: "new-password" }}
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <TextField
              error={errors.includes("password")}
              fullWidth
              inputProps={{ autoComplete: "new-password" }}
              type="password"
              label="Contraseña"
              variant="outlined"
              value={user.password}
              onChange={(event) => onChange("password", event.target.value)}
              helperText={'Favor de llenar el campo "Contraseña".'}
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <TextField
              error={errors.includes("confirmPassword")}
              fullWidth
              type="password"
              label="Confirmar contraseña"
              variant="outlined"
              value={user.confirmPassword}
              onChange={(event) =>
                onChange("confirmPassword", event.target.value)
              }
              helperText={'Favor de llenar el campo "Confirmar contraseña".'}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginTop: 15 }}>
        <Button
          variant="contained"
          onClick={() => {
            onClose();
          }}
        >
          Cancelar
        </Button>
        <Button onClick={handleAccept} variant="contained" color="secondary">
          {saving ? (
            <CircularProgress size={25} style={{ color: "white" }} />
          ) : (
            "Guardar"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UsersDialogView;
