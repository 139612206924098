import React, { useEffect, useState } from "react";
import { Box, Container, makeStyles, Button } from "@material-ui/core";
import Page from "../../components/Page";
import Loader from "../../components/Loader";
import Results from "./Results";
import FormDialog from "./Modal.js";
import QRCode from "easyqrcodejs";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import DeleteDialog from "../../components/DeleteDialog";
import SyncDialog from "./ModalSync.js";
import app from "../../firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100vh",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));
const code = React.createRef();

const DeviceListView = ({ history }) => {
  useFirestoreConnect([
    {
      collection: "dispositivos",
      where: [["activo", "==", "si"]] /* orderBy: ['name'] */,
    },
  ]);
  const dispositivos = useSelector(
    (state) => state.firestore.ordered.dispositivos
  );
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("");
  const [urls, setUrls] = useState([]);
  const [openSync, setOpenSync] = React.useState(false);
  const [devices, setDevices] = useState([]);
  const [device, setDevice] = useState({});
  const [openDelete, setOpenDelete] = React.useState(false);
  const db = app.firestore();

  const deleteDevice = async () => {
    let d = { ...device };
    d.activo = "no";
    d.borrado = app.firestore.Timestamp.now();
    db.collection("dispositivos").doc(device.id).update(d);
    setOpenDelete(false);
  };


  const syncRelations = (id) => {
    try {
      db.collection("relaciones")
        .where("usuario", "==", id)
        .get()
        .then((querySnapshot) => {
          const data2 = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          try {
            getQR(data2);
          } catch (e) {
            console.log(e);
          }
          setRelations(data2);
          syncDevices();
        });
    } catch (e) {
      console.log(e);
      console.log(id);
    }
  };
  useEffect(() => {
    let isMounted = true;

    if (dispositivos && dispositivos.length) {
      let urls = [];

      dispositivos.forEach((d) => {
        new QRCode(code.current, {
          text: d.id,
          width: 256,
          height: 256,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
          dotScale: 1,
          onRenderingEnd: function (_, dataURL) {
            urls.push(dataURL);
          },
        });
      });

      if (isMounted) {
        setUrls(urls);
      }
    }

    return () => (isMounted = false);
  }, [dispositivos]);

  if (!isLoaded(dispositivos)) {
    return <Loader />;
  }

  return (
    <Page className={classes.root} title="Clientes">
      <div
        ref={code}
        style={{
          display: "none",
          visibility: "hidden",
          maxWidth: 0,
          maxHeight: 0,
        }}
      ></div>
      <Container maxWidth={false}>
        <Box align="right">
          <Button
            align="right"
            color="primary"
            onClick={() => {
              setMode("add");
              setOpen(true);
            }}
            variant="contained"
          >
            Nuevo
          </Button>
        </Box>
        <FormDialog
          open={open}
          user={device}
          setOpen={() => setOpen(false)}
          onChange={(prop, value) => setDevice({ ...device, [prop]: value })}
          mode={mode}
        />
        <DeleteDialog
          open={openDelete}
          onCancel={() => setOpenDelete(false)}
          onClose={() => setOpenDelete(false)}
          onAcceptDelete={deleteDevice}
        />
        <SyncDialog
          device={device}
          urls={urls}
          devices={devices}
          openSync={openSync}
          handleSyncRelation={() => setOpenSync(false)}
          deleteRelation={() => setOpenSync(false)}
          onClose={() => setOpenSync(false)}
        />
        <Box mt={3}>
          <Results
            viewDevice={(row) => {
              history.push({
                pathname: "/device",
                state: {
                  id: row.id,
                },
              });
            }}
            data={dispositivos}
            urls={urls}
            handleEdit={(row) => {
              setMode("update");
              setOpen(true);
              setDevice(row);
            }}
            handleDelete={(row) => {
              setOpenDelete(true);
              setDevice(row);
              // setUser(row);
            }}
            handleSync={(row) => {
              setDevice(row);
              setOpenSync(true);
              //syncRelations(row.id);
            }}
          />
        </Box>
        <Box height="60"></Box>
      </Container>
    </Page>
  );
};

export default DeviceListView;
