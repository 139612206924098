import React from "react";
import { Box, Container, Grid, makeStyles,Button,TextField } from "@material-ui/core";
import Page from "../../components/Page";
import Budget from "./Budget";
import Results from "./Results";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import DeleteDialog from "../../components/DeleteDialog";
import app from "../../firebase";
import useSound from "use-sound";
import boopSfx from "../../sounds/notification_decorative-01.wav";
import Dialog from './Dialog'
import XLSX from "xlsx";
import Searchbox from "../../components/SearchBoxDate";
import { esES } from '@material-ui/core/locale';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import ChatView from './chat'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "60%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Dashboard = ({ history }) => {
  var date = new Date();
  var month=date.getMonth()+1;
  if(month>=10){
    var formatedDate = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`

  }else{
    var formatedDate = `${date.getFullYear()}-0${date.getMonth()+1}-${date.getDate()}`

  }
  const [openChat, setOpenChat] = React.useState(false);

  const db = app.firestore();
  const [pedidos, setPedidos] = React.useState([]);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [mode, setMode] = useState("");
  const [model, setModel] = useState({status:"Todos",enddate:"",startdate:""});
  const [title, setTitle] = useState("");
  const auth = useSelector((state) => state.firebase.auth);
  const [play] = useSound(boopSfx);
  const [pedido, setPedido] = React.useState({});
  const [filter, setFilter] = React.useState(" ");
  const [todos, setTodos] = React.useState();
  const [pedidoTerminado, setPedidoTerminado] = React.useState();
  const [pedidoNuevo, setPedidoNuevo] = React.useState();
  const [pedidoCancelado, setPedidoCancelado] = React.useState();
  const [pedidoAsignado, setPedidoAsignado] = React.useState();
  const [pedidoProcesso, setPedidoProcesso] = React.useState();
  const [folioPedido, setfolioPedido] = useState({});
  
  const [searchText, setSearchText] = useState("");
  const [StartDate, setStartDate] = useState();
  const [EndDate, setEndDate] = useState();
  
  const searchfilter=["folio","nombre"];

  const [chatId, setChatId] = useState("");
  const [message, setMessage] = useState("");
  const [choferImage, setchoferImage] = useState("");
  const [usuarioImage, setusuarioImage] = useState("");
  const [chatWindow, setchatWindow] = useState("null");
  const [chatWindowSalas, setchatWindowSalas] = useState([]);
  const [currentMessages, setCurrentMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  useFirestoreConnect([
    {
      collection: "salas",
      orderBy: ["modificado", "desc"],
    },
    
  
  ]);
  useFirestoreConnect([
    {
      collection: "mensajes",
      orderBy: ["fecha", "asc"],
      where:[["idSala","==",chatId]],
    },  ]);
  const messageSelector = useSelector(
    (state) => state.firestore.ordered.mensajes
  );
  const salasSelector = useSelector(
    (state) => state.firestore.ordered.salas
  );
  const handleOpenChat = (row) => {
    setfolioPedido(row);
    setOpenChat(true);};
  const handleCloseChat = () => setOpenChat(false);
  
  const filterData = (data, filter, searchText) => {
    if (Array.isArray(filter)) {
      // the 'filter' can be an array of properties to filter by
      data = data.filter((item) => defaultFilter(item, filter, searchText));
    } else {
      // or it can be a callback to use as a customized filter
      data = data.filter((item) => filter(item, searchText));
    }
    return data;
  };
// filter used by the searchbox
const defaultFilter = (item, props, searchtext) =>
  props.some(
    (prop) =>
      item[prop] && item[prop].toString().toUpperCase().includes(searchtext)
  );

  useEffect(() => {
    if (searchfilter && searchText) {
      // if there is a filter as prop then we filter the data
      const filteredData = filterData(pedidosSelector, searchfilter, searchText);
      setPedidos(filteredData);
      
    } 
  }, [searchText]);
  var originalDate = new Date();
  var date= getNewDate(originalDate, -3) 
  var end= getNewDate(originalDate, 1) 

  
  const [Timestamp, setStamp] = useState( app.firestore.Timestamp.fromDate(date));

  const [EndTimestamp, setEndTimestamp] = useState( app.firestore.Timestamp.fromDate(end));


  const [isTokenFound, setTokenFound] = useState(false);
  {
    isTokenFound && <h1> Notification permission enabled 👍🏻 </h1>;
  }
  {
    !isTokenFound && <h1> Need notification permission ❗️ </h1>;
  }
  const handleComboClose = () => {
    setComboOpen(false);
  };

  const handleComboOpen = () => {
    setComboOpen(true);
  };

  function getNewDate(originalDate, dateOffset){
    // converting original date to milliseconds
    var originalMS = Date.parse(originalDate);
    // calculating your date offest in milliseconds
    var offsetMS = dateOffset * 1000 * 3600 * 24;
    // apply the offset millisecons to original moment
    var newDateMS = originalMS + offsetMS;
    // Convert it back to new date object and return it
    return new Date(newDateMS);
}


  useFirestoreConnect([
    {
      collection: "pedidos",
      orderBy: ["fecha", "desc"],
      where: [["fecha", ">", Timestamp],["fecha", "<", EndTimestamp]] 
    },

  ]);

  const pedidosSelector = useSelector(
    (state) => state.firestore.ordered.pedidos
  );

  const classes = useStyles();

  useEffect(() => {
    try {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      const todayTimestamp = app.firestore.Timestamp.fromDate(date);
      if (pedidosSelector) {
        let _pedidos = [...pedidosSelector];

        setPedidos(_pedidos);
      }
      db.collection("pedidos")
        .where("fecha", ">", todayTimestamp)
        .get()
        .then(function (querySnapshot) {
          setTodos(querySnapshot.docs.length);
        });
      db.collection("pedidos")
        .where("estatus", "==", "NUEVO")
        .where("fecha", ">", todayTimestamp)
        .get()
        .then(function (querySnapshot) {
          var p = querySnapshot.docs.map((doc) => doc.data());
          setPedidoNuevo(querySnapshot.docs.length);
          
        });

      db.collection("pedidos")
        .where("estatus", "==", "CANCELADO")
        .where("fecha", ">", todayTimestamp)
        .get()
        .then(function (querySnapshot) {
          setPedidoCancelado(querySnapshot.docs.length);
        });
      db.collection("pedidos")
        .where("estatus", "==", "TERMINADO")
        .where("fecha", ">", todayTimestamp)
        .get()
        .then(function (querySnapshot) {
          setPedidoTerminado(querySnapshot.docs.length);
        });
      db.collection("pedidos")
        .where("estatus", "==", "EN RUTA")
        .where("fecha", ">", todayTimestamp)
        .get()
        .then(function (querySnapshot2) {
          setPedidoProcesso(querySnapshot2.docs.length);
        });
      db.collection("pedidos")
        .where("estatus", "==", "ASIGNADO")
        .where("fecha", ">", todayTimestamp)
        .get()
        .then(function (querySnapshot) {
          setPedidoAsignado(querySnapshot.docs.length);
        });
    } catch (e) {
      console.log(e);
    }
  }, [pedidosSelector]);

  const getNewMessageCount=async (idSala)=>{
    let size=1;
    await db.collection('mensajes').where("idSala","==",idSala).where("idUsuario","!=","callcenter").where("visto","==",0).get().then(snapshot => {
      size=snapshot.size;
  });
  return(size); 
  

  }; 
  const deletePedido = async () => {
    db.collection("pedidos").doc(pedido.id).update({ estatus: "CANCELADO" });

    setOpenDelete(false);
  };
  const filterNuevo = () => {
    setFilter("NUEVO");
  };
  const filterAsignado = () => {
    setFilter("ASIGNADO");
  };
  const filterRuta = () => {
    setFilter("EN RUTA");
  };
  const filterTerminado = () => {
    setFilter("TERMINADO");
  };
  const filterCancelado = () => {
    setFilter("CANCELADO");
  };
  const filterAll = () => {
    setFilter(" ");
  };

  const generarReporte = async () => {

   var startDate = model.startdate;
   var endDate = model.enddate;
   var status = model.status;

  let reportname=model.startdate+"__"+model.enddate+".pdf"

;
try{
  startDate = startDate.split("-");
}catch{

}
try{
  endDate = endDate.split("-");
}catch{

}   
   if(startDate.length>1){
    var newStartDate = new Date(startDate);
   }else{
   var  newStartDate=new Date();
   }
   if(endDate.length>1){
    var newEndDate = new Date(endDate);

   }else{
    var newEndDate=new Date();
   }
   newStartDate.setHours(0, 0, 0, 0);
   newEndDate.setHours(24, 0, 0, 0);

   var Start=app.firestore.Timestamp.fromDate(newStartDate);

   var End=app.firestore.Timestamp.fromDate(newEndDate);
   var startref;
   if (status=="TODOS"){
   var startRef=db.collection("pedidos").where("fecha",">",Start);
     
   }else{
   var startRef=db.collection("pedidos").where("estatus","==",status).where("fecha",">",Start);

   }
   const endRef=startRef.where("fecha","<",End);

  let entries=[];
   await endRef.get().then(snapshot => {
     for (let index = 0; index < snapshot.docs.length; index++) {
      const element = snapshot.docs[index];
      entries.push(element.data());
    }

});
generateExcel(entries);




    setOpen(false);
  };



  function generateExcel(entries) {
    const filename = `pedidos_${Date.now().toString()}.xlsx`;
    const ws_name = "Pedidos";
    let wb = {
      SheetNames: [],
      Sheets: {},
    };

    let ws = {
      A2: { v: "Folio", t: "s" },
      B2: { v: "Cliente", t: "s"},
      C2: { v: "Chofer", t: "s" },
      D2: { v: "Fecha", t: "s" },
      E2: { v: "Método   de Pago", t: "s" },
      F2: { v: "Litros", t: "s" },
      G2: { v: "Total", t: "s" },
      H2: { v: "Calificación ", t: "s" },
      I2: { v: "Estatus", t: "s" },
      J2: { v: "Comentarios", t: "s" },
      K2: { v: "Comentarios Calificacion", t: "s" },
      L2: { v: "Comentarios Chofer ", t: "s" },
      M2: { v: "Comentarios Pedido", t: "s" },
    };
    let renInicial = 3;
    for (let i in entries) {
      let options = {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit'
    };
    let date=new Date(entries[i].fecha.toDate()).toLocaleTimeString("es-MX",options);
      
      ws["A" + renInicial] = { v: entries[i].folio, t: "s" };
      ws["B" + renInicial] = { v: entries[i].nombre, t: "s" };
      ws["C" + renInicial] = { v: entries[i].chofer.nombre, t: "s" };
      ws["D" + renInicial] = { v: date, t: "s" };
      ws["E" + renInicial] = { v: entries[i].metodopago, t: "s" };
      ws["F" + renInicial] = { v: entries[i].litros.toFixed(2), t: "s" };
      ws["G" + renInicial] = { v: entries[i].total, t: "s" };
      ws["H" + renInicial] = { v: entries[i].calificacion, t: "s" };
      ws["I" + renInicial] = { v: entries[i].estatus, t: "s" };
      ws["J" + renInicial] = { v: entries[i].comentarios, t: "s" };
      ws["K" + renInicial] = { v: entries[i].comentariosCalificacion, t: "s" };
      ws["L" + renInicial] = { v: entries[i].comentariosChofer, t: "s" };
      ws["M" + renInicial] = { v: entries[i].comentariosPedido, t: "s" };

      renInicial++;
    }
    var wscols = [
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
    ];
    ws["!cols"] = wscols;
    ws["!ref"] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: 15, r: renInicial },
    });
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    XLSX.writeFile(wb, filename, { cellStyles: true });
  }

 


  return (
    <Page className={classes.root} title="Dashboard">
         <Modal
        open={openChat}
        onClose={handleCloseChat}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box  sx={style}>
          <ChatView folioPedido={folioPedido}/>
        </Box>
      </Modal>
      <DeleteDialog
        open={openDelete}
        onCancel={() => setOpenDelete(false)}
        onClose={() => setOpenDelete(false)}
        onAcceptDelete={deletePedido}
      />
      <Dialog
        onChange={(key, value) => {
        setModel({...model, [key]: value});
        }}   
        title={"Nuevo Reporte"}
        model={model}
        open={open}
        onCancel={() =>{ setOpen(false)}}
        onClose={() => {setOpen(false)}}
        onAccept={()=>{generarReporte();}}
      />
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Budget
              title={"Pedidos Nuevos del Día"}
              info={"NUEVO"}
              data={pedidoNuevo}
              filter={filterNuevo}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Budget
              title={"Pedidos Asignados"}
              info={"ASIGNADO"}
              data={pedidoAsignado}
              filter={filterAsignado}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Budget
              title={"Pedidos en Ruta"}
              info={"EN RUTA"}
              data={pedidoProcesso}
              filter={filterRuta}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Budget
              title={"Pedidos Terminados"}
              info={"TERMINADO"}
              data={pedidoTerminado}
              filter={filterTerminado}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Budget
              title={"Pedidos Cancelados"}
              info={"CANCELADO"}
              data={pedidoCancelado}
              filter={filterCancelado}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Budget
              title={"Todos"}
              info={"todos"}
              data={todos}
              filter={filterAll}
            />
          </Grid>
   
        </Grid>
       


        <Box align="right">
          <Button
            align="right"
            color="primary"
            onClick={()=>{setOpen(true)}}
            variant="contained"
          >
            Nuevo REPORTE
          </Button>
        </Box>
        <Searchbox
            onChange={(key, value) => {
            if(key=="text"){
            setSearchText(value.trim().toUpperCase());

            }
            if(key=="startdate"){
              let startDate = value.split("-");
               startDate = new Date(startDate);
               startDate.setHours(0, 0, 0, 0);
               startDate=app.firestore.Timestamp.fromDate(startDate);
               setStamp(startDate);
            }
            if(key=="enddate"){
              let endDate = value.split("-");
               endDate = new Date(endDate);
               endDate.setHours(24, 0, 0, 0);
               endDate=app.firestore.Timestamp.fromDate(endDate); 
               setEndTimestamp(endDate);
            }
              }}   
          />
        <Box mt={3}>
          {filter == " " ? (
            <Results
            handleOpenChat={
              (row) => {
               handleOpenChat(row);
              }
            }
              onClick={(row) => {
                history.push({
                  pathname: "/pedido",
                  state: {
                    id: row.id,
                  },
                });
              }}
              handleEdit={(row) => {
                setMode("update");
                setOpen(true);
                setPedido(row);
              }}
              data={pedidos}
              handleDelete={(row) => {
                setOpenDelete(true);
                setPedido(row);
                // setUser(row);
              }}
            />
          ) : (
            <Results
              onClick={(row) => {
                history.push({
                  pathname: "/pedido",
                  state: {
                    id: row.id,
                  },
                });
              }}
              data={pedidos.filter((a) => a.estatus === filter)}
              handleDelete={(row) => {
                setOpenDelete(true);
                setPedido(row);
                // setUser(row);
              }}
            />
          )}
        </Box>
        <Box height="60"></Box>
      </Container>
    </Page>
  );
};

export default Dashboard;
