import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useCallback, useState, useEffect } from "react";
import axios from "axios";
import app from "../../firebase";
const db = app.firestore();

export default function FormDialog({ open, setOpen, onChange, user, mode }) {
  const handleAccept = async () => {
    if (!user.id_unico || !user.provedor || !user.tipo) {
      alert("todos los campos son requeridos");
      return;
    }
    if (mode == "update") {

      user.modificado = app.firestore.Timestamp.now();
      db.collection("dispositivos").doc(user.id).update(user);
      setOpen();

    
    }
    if (mode == "add") {
      console.log(user);
      user.activo = "si";
      user.fecha = app.firestore.Timestamp.now();
      db.collection("dispositivos").doc().set(user);

      /*       const url =
        "https://us-central1-edesarrollos-iot.cloudfunctions.net/create-device";

      const respo = await axios.post(url, {
        id_unico: user.id_unico,
        provedor: user.provedor,
        tipo: user.tipo,
      });
      console.log("res:", respo.data);
    }*/
    }
    setOpen();
  };

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Agregar Dispositivo</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="id_unico"
          label="ID Unico"
          type="text"
          fullWidth
          value={user.id_unico || ""}
          onChange={(event) => onChange("id_unico", event.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="provedor"
          label="Provedor"
          type="text"
          fullWidth
          value={user.provedor || ""}
          onChange={(event) => onChange("provedor", event.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="tipo"
          label="Tipo de Sensor"
          type="text"
          fullWidth
          value={user.tipo || ""}
          onChange={(event) => onChange("tipo", event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen()} color="primary">
          Cancelar
        </Button>
        {mode === "add" && (
          <Button onClick={handleAccept} color="primary">
            Aggregar
          </Button>
        )}
        {mode === "update" && (
          <Button onClick={handleAccept} color="primary">
            Modificar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
