import React, {useCallback, useState} from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from '@material-ui/core';
import Cropper from 'react-easy-crop';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Crop from '../../components/Crop';

const HomeDialogView = ({open, title, onAccept, onCancel, onChange, model, saving}) => {
  const {imagen = ''} = model;
  const [errors, setErrors] = useState([]);

  const handleAccept = () => {

    let _errors = [];
    if (!model.nombre) {
      _errors.push('nombre');
    }

    if (!model.descripcion) {
      _errors.push('descripcion');
    }

    /*if (!model.inicio) {
      _errors.push('inicio');
    }

    if (!model.fin) {
      _errors.push('fin');
    }*/

    if (!model.activo) {
      model.activo = false;
    }


    setErrors(_errors);

    if (_errors.length) {
      console.log(errors);
      return;
    }

    console.log(model);
    onAccept();
  };


  function zfill(number, width) {
    let numberOutput = Math.abs(number);
    /* Valor absoluto del número */
    let length = number.toString().length;
    /* Largo del número */
    let zero = "0";
    /* String de cero */

    if (width <= length) {
      if (number < 0) {
        return ("-" + numberOutput.toString());
      } else {
        return numberOutput.toString();
      }
    } else {
      if (number < 0) {
        return ("-" + (zero.repeat(width - length)) + numberOutput.toString());
      } else {
        return ((zero.repeat(width - length)) + numberOutput.toString());
      }
    }
  }
  let inicio = Object.keys(model).length > 0 && model.inicio !== undefined ? new Date(model.inicio.seconds * 1000 ): new Date(), fin = Object.keys(model).length> 0 && model.fin !== undefined  ? new Date( model.fin.seconds * 1000 ): new Date();

  return (
    <Dialog
      style={{minWidth: "80% !important"}}
      open={open}
    >
      <DialogTitle>Agregar {title}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              error={errors.includes('nombre')}
              variant="outlined"
              value={model.nombre}
              fullWidth
              onChange={event => onChange('nombre', event.target.value)}
              helperText={'Nombre de la Promoción.'}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={errors.includes('descripcion')}
              variant="outlined"
              value={model.descripcion}
              fullWidth
              multiline
              onChange={event => onChange('descripcion', event.target.value)}
              helperText={'Descripción de la promoción.'}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={errors.includes('informacion')}
              variant="outlined"
              value={model.informacion}
              fullWidth
              onChange={event => onChange('informacion', event.target.value)}
              helperText={'Informacion de la Promoción'}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              error={errors.includes('inicio')}
              variant="outlined"
              defaultValue={inicio.getFullYear() + "-" + zfill((inicio.getMonth() + 1), 2) + "-" + zfill(inicio.getDate(), 2)}
              type="date"
              fullWidth
              onChange={event => onChange('inicio', event.target.value)}
              helperText={'Inicio de la promoción'}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              error={errors.includes('fin')}
              variant="outlined"
              defaultValue={fin.getFullYear() + "-" + zfill((fin.getMonth() + 1), 2) + "-" + zfill(fin.getDate(), 2)}
              type="date"
              fullWidth
              onChange={event => onChange('fin', event.target.value)}
              helperText={'Fin de la promoción'}
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              control={<Switch checked={model.activo}
                               onChange={event => onChange('activo', event.target.value)}/>}
              label="Promocion activa"
            />
          </Grid>
          <Grid item xs={12}>

            <TextField
              error={errors.includes('url')}
              variant="outlined"
              value={model.url}
              fullWidth
              multiline
              onChange={event => onChange('url', event.target.value)}
              helperText={'Pagina de Redirección'}
            />
          </Grid>
        </Grid>


        <Grid container>
          <Grid item md={3} xs={3}>
            <Button style={{backgroundColor: '#36bea1', color: 'white'}} component="label">
              EDITAR
              <input type="file" accept="image/x-png,image/gif,image/jpeg"
                     style={{display: "none"}}
                     onChange={event => event.target.files.length && onChange('imagen', event.target.files[0])}
              />
            </Button>
          </Grid>

          <Grid item md={9} xs={9}>
            <Crop
              _state={
                imagen
                ?
                  {
                    image: typeof imagen === "string" ?  imagen : URL.createObjectURL(imagen),
                    crop: { x: 0, y: 0 },
                    zoom: 1,
                    aspect: 3 / 4,
                  }
                :
                  null
              }
              onCroppedImage={(imageCropped) => onChange('imagen', imageCropped)}
            />
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} style={{color: 'white', backgroundColor: '#232e5c'}}>
          Cancelar
        </Button>
        {
          saving
            ?
            <CircularProgress style={{color: '#232e5c'}}/>
            :
            <Button onClick={handleAccept} style={{color: 'white', backgroundColor: '#232e5c'}} autoFocus>
              Aceptar
            </Button>
        }
      </DialogActions>
    </Dialog>
  )
}

export default HomeDialogView
