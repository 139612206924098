import React, { useState } from "react";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const validateEmail = (email) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email.toLowerCase()
  );

const UsersDialogView = ({
  open,
  onClose,
  onAccept,
  mode,
  user = {},
  onChange,
  saving,
  onErrorPasswords,
}) => {
  const [errors, setErrors] = useState([]);

  if (!user) return null;

  const handleAccept = () => {
    if (saving) return;

    let _errors = [];

    if (!validateEmail(user.email)) {
      _errors.push("email");
    }

    if (
      (user.password && mode === "edit") ||
      (user.confirmPassword && mode === "edit") ||
      mode === "add"
    ) {
      if (user.password.length < 7 || user.confirmPassword.length < 7) {
        _errors.push("passwords");
        onErrorPasswords("La contraseña debe tener un mínimo de 8 caracteres.");
      }

      if (user.password !== user.confirmPassword) {
        _errors.push("passwords");
        onErrorPasswords("Las contraseñas no coinciden.");
      }
    }

    if (!user.displayName) {
      _errors.push("displayName");
    }

    setErrors(_errors);

    if (_errors.length) return;

    onAccept();
  };

  return (
    <Dialog maxWidth="md" open={open}>
      <DialogTitle>
        {mode === "add" ? "Agregar usuario" : "Editar usuario"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12} sm={12}>
            <TextField
              error={errors.includes("displayName")}
              fullWidth
              type="text"
              label="Nombre"
              variant="outlined"
              value={user.displayName}
              onChange={(event) => onChange("displayName", event.target.value)}
              helperText={'Favor de llenar el campo "Nombre".'}
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <TextField
              error={errors.includes("email")}
              fullWidth
              type="text"
              label="Corre electrónico"
              variant="outlined"
              value={user.email}
              onChange={(event) => onChange("email", event.target.value)}
              helperText={'Favor de escribir un "Corre electrónico" válido.'}
              inputProps={{ autoComplete: "new-password" }}
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <TextField
              error={errors.includes("password")}
              fullWidth
              inputProps={{ autoComplete: "new-password" }}
              type="password"
              label="Contraseña"
              variant="outlined"
              value={user.password}
              onChange={(event) => onChange("password", event.target.value)}
              helperText={'Favor de llenar el campo "Contraseña".'}
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <TextField
              error={errors.includes("confirmPassword")}
              fullWidth
              type="password"
              label="Confirmar contraseña"
              variant="outlined"
              value={user.confirmPassword}
              onChange={(event) =>
                onChange("confirmPassword", event.target.value)
              }
              helperText={'Favor de llenar el campo "Confirmar contraseña".'}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginTop: 15 }}>
        <Button
          variant="contained"
          onClick={() => {
            onClose();
          }}
        >
          Cancelar
        </Button>
        <Button onClick={handleAccept} variant="contained" color="secondary">
          {saving ? (
            <CircularProgress size={25} style={{ color: "white" }} />
          ) : (
            "Guardar"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UsersDialogView;
