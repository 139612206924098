import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import Table from "../../components/Table";
import { Card, makeStyles, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import PrintIcon from "@material-ui/icons/LocalPrintshop";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import SyncAlt from "@material-ui/icons/SyncAlt";

const header = [
  {
    prop: "nombre",
    name: "Nombre",
  },

  {
    prop: "correo",
    name: "Email",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const Results = ({ handleSync, handleEdit, data, urls, handleDelete }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handlePrint = (id) => {
    const index = data.map((d) => d.id).indexOf(id);
    const a = document.createElement("a");
    a.href = urls[index];
    a.download = "Image.png";
    a.click(); //Downloaded file
  };

  return (
    <Card style={{ padding: 15 }}>
      {data && (
        <Table
          header={header}
          data={data}
          paginated
          filter={["nombre", "correo"]}
          extraRows={[
            {
              prop: "sync",
              name: "Relacionar",
              cell: (row) => (
                <IconButton
                  style={{ backgroundColor: "#03a5fc", color: "white" }}
                  onClick={() => handleSync(row)}
                >
                  <SyncAlt />
                </IconButton>
              ),
            },
          ]}
        />
      )}
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;
