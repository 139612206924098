import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

const DeleteDialog = ({ open, onClose, onCancel, onAccept }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>¡Advertencia!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          ¿Seguro que deseas finalizar este pedido?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancelar
        </Button>
        <Button onClick={onAccept} color="primary" autoFocus>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
