import React, { useEffect } from "react";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Container,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Page from "../../components/Page";
import Modal from "./Modal";
import Results from "./Results";
import { set } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const title = "Firmware Update Module";

const Dashboard = () => {
  useFirestoreConnect([
    {
      collection: "firmware",
    },
  ]);
  const [openCombo, setComboOpen] = React.useState(false);
  const [firmware, setFirmware] = React.useState({});
  const [firmwares, setFirmwares] = React.useState([]);

  const [model, setModel] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const handleComboClose = () => {
    setComboOpen(false);
  };

  const handleComboOpen = () => {
    setComboOpen(true);
  };

  const firmwareSelector = useSelector(
    (state) => state.firestore.ordered.firmware
  );

  const classes = useStyles();

  useEffect(() => {
    try {
      if (firmwareSelector) {
        let _firmware = [...firmwareSelector];

        setFirmwares(_firmware);
      }
    } catch (e) {
      console.log(e);
    }
  }, [firmwareSelector]);

  return (
    <Page className={classes.root} title={title}>
      <Container maxWidth={false}>
        <Box display="flex" p={1} mt={2}>
          <Box flexGrow={1}>
            <Typography variant="h5">{title}</Typography>
          </Box>
          <Box>
            <Button
              color="primary"
              onClick={() => {
                setOpen(true);
                setFirmware({ version: "", descripcion: "" });
              }}
              variant="contained"
            >
              Nuevo
            </Button>
          </Box>
        </Box>
        <Box mt={3}>
          <Results data={firmwares} title={title} />
        </Box>
        <Box height="60"></Box>
      </Container>
      <Modal
        open={open}
        setOpen={() => {
          setOpen(false);
        }}
        onChange={(key, value) => {
          setFirmware({ ...firmware, [key]: value });
        }}
        onCancel={() => {
          setOpen(false);
          setModel({});
        }}
        firmware={firmware}
        title={title}
      />
    </Page>
  );
};

export default Dashboard;
